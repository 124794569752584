<template>
  <div class="tabs__block pt-5">
    <div class="tabs d-flex justify-content-center">
      <a
        v-on:click="activetab = '1'"
        v-bind:class="[activetab === '1' ? 'active' : '']"
        ><h2 class="tab-title px-3">我們的服務</h2></a
      >
      <a
        v-on:click="activetab = '2'"
        v-bind:class="[activetab === '2' ? 'active' : '']"
        ><h2 class="tab-title px-2 px-sm-3">服務成果分享</h2></a
      >
    </div>
    <div class="content">
      <div v-if="activetab === '1'" class="tabcontent">
        <slot name="content1"></slot>
      </div>
      <div v-if="activetab === '2'" class="tabcontent">
        <slot name="content2"></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      activetab: "1",
    };
  },
};
</script>
<style lang="scss" scoped>
.tab-title {
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  line-height: 20px;
  cursor: pointer;
  @media (max-width:320px) {
    font-size: 20px;
  }
}
</style>
