import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import VueSplide from "@splidejs/vue-splide";
import VueSmoothScroll from "vue3-smooth-scroll";
import "bootstrap";
import "@splidejs/splide/dist/css/splide.min.css";
import AOS from "aos";
import "aos/dist/aos.css";

const app = createApp(App)
  .use(store)
  .use(router)
  .use(VueSplide)
  .use(VueSmoothScroll)
  .use(AOS)

//global filters
//濾html標籤 /(<([^>]+)>)/ig
//濾空格 /\s*/g
app.config.globalProperties.$filters = {
  dateFormat(str) {
    if (str!=null) {
      return str.replace(/-/gi, '/');
    }
    
  },
  dateFormatDot(str) {
    return str.replace(/-/gi, '.');
  },
  removeHtmlTag(str) {
    if (str!=null) {
      return str.replace(/(<([^>]+)>)\s*/ig, '');
    }
  },
  removeHtmlTagP(str) {
    if (str!=null) {
      return str.replace(/<\/?p>/ig, '');
    }
  },
  removeWordSpace(str) {
    return str.replace(/\s*/g, '');
  },
}

//創造模板語法
app.directive("focus",{
  mounted(el){
    el.focus();
  }
})

app.mount("#app");