<template>
  <div class="service__container">
    <!-- BANNER -->
    <template v-for="item in pagesBanner" :key="item.ID">
      <banner-single v-if="item.Pages == 'service'">
        <template #image>
          <div
            class="bannerbox__image d-none d-sm-block"
            :style="{ backgroundImage: 'url(' + item.Img + ')' }"
          ></div>
          <div
            class="bannerbox__image d-block d-sm-none"
            :style="{ backgroundImage: 'url(' + item.MImg + ')' }"
          ></div>
        </template>
        <template #title
          ><h1 class="bannerbox__title">{{ item.Title }}</h1></template
        >
        <template #desc
          ><p>{{ item.SubTitle }}</p></template
        >
        <template #mark>
          <img
            src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/mark-banner-secondary.svg"
            class="img-fluid banner__mark"
          />
        </template>
      </banner-single>
    </template>

    <!-- MAIN -->
    <article class="bg-secondary">
      <tabs>
        <template #content1>
          <section
            :id="'sec' + (index + 1)"
            :class="{
              sec: 'sec',
              'bg-secondary': (index + 1) % 2 !== 0,
              'bg-primary': (index + 1) % 2 == 0,
            }"
            v-for="(item, index) in serviceList"
            :key="item.id"
          >
            <div class="container">
              <div
                class="row justify-content-center"
                :class="{ 'flex-row-reverse': (index + 1) % 2 == 0 }"
              >
                <div class="col-md-6 d-flex mt-lg-auto align-items-md-center mb-4">
                  <img-box class="custom-border">
                    <template #image>
                      <div class="imgbox__inner imgbox--ratio-5x3 imgbox--ratio-pad">
                        <div
                          class="imgbox__image"
                          :style="{
                            backgroundImage: 'url(' + item.ListImg + ')',
                          }"
                          style="background-size: cover"
                        ></div>
                      </div>
                    </template>
                  </img-box>
                </div>
                <div class="col-md-6 d-flex flex-column align-items-center">
                  <h2
                    class="bubble-tag bubble--size9x9"
                    v-html="item.Title"
                  ></h2>
                  <p class="text-box">{{ item.ListIntroduction }}</p>
                  <!-- <a
                    :href="item.UserStory"
                    class="
                      btn btn-pill btn-light
                      d-flex
                      justify-content-center
                      w-50
                    "
                    >更多故事<span class="icon-arrow-right"
                      ><span></span><span></span></span
                  ></a> -->
                </div>
              </div>
            </div>
            <img
              src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/bg-secondary-gradient.png"
              class="img-fluid sec-deco-bottom"
              v-if="(index + 1) % 2 !== 0"
            />
            <img
              src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/mark-banner-primary.svg"
              class="img-fluid sec-deco-bottom"
              v-if="(index + 1) % 2 !== 0"
            />
            <img
              src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/mark-banner-secondary.svg"
              class="img-fluid sec-deco-bottom"
              v-if="(index + 1) % 2 == 0"
            />
          </section>
        </template>

        <template #content2>
          <section class="container px-0">
            <tabs-slides :data_Y="serviceResult_Y" v-on:getYear="getYear">
              <template #activetab>
                <div class="container px-0">
                  <section-fancy
                    :serviceResult="serviceResult2"
                  ></section-fancy>
                </div>
              </template>
            </tabs-slides>
          </section>
        </template>
      </tabs>
      <section class="sec">
        <h2 class="sec__title"></h2>
        <div class="sec__content container"></div>
      </section>
    </article>
  </div>
</template>

<script>
import axios from "axios";

import BannerSingle from "@/components/BannerSingle.vue";
//import SliderSimple from "@/components/SliderSimple.vue";
import ImgBox from "@/components/ImgBox.vue";
import Tabs from "@/components/Tabs.vue";
import TabsSlides from "@/components/TabsSlides.vue";
import SectionFancy from "@/components/SectionFancy.vue";

export default {
  components: {
    BannerSingle,
    //SliderSimple,
    Tabs,
    TabsSlides,
    SectionFancy,
    ImgBox,
  },
  data() {
    return {
      seoData: null,
      sTitle: null,
      sDescription: null,
      sKeywords: null,
      sSiteName: null,
      sImage: null,
      sType: null,
      currentURL: null,
      count: 0,
      pagesBanner: [],
      activetab: null,
      serviceList: [],
      serviceResult_Y: [],
      serviceResult: [],
      serviceResultAll: [],
    };
  },
  created() {
    var seoFD = new FormData();
    seoFD.append("mode", "raw_sct");
    seoFD.append("data", '{"class":"HciSEO","lang":"zh-TW"}');
    axios({
      method: "post",
      url: "https://nkg-web-api.japaneast.cloudapp.azure.com/Form/",
      data: seoFD,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      const currtUrlName = this.$route.name.toLowerCase();
      this.seoData = res.data.data.filter(item => (item.SetPage == currtUrlName));
      this.sTitle = this.seoData[0].title;
      this.sDescription= this.seoData[0].description;
      this.sKeywords = this.seoData[0].keywords;
      this.sSiteName = this.seoData[0].site_name;
      this.sImage = this.seoData[0].image;
      this.sType = this.seoData[0].type;
      this.currentURL = window.location.href;
      
      console.log("this.currentURL=====>", this.currentURL)

      const siteTitle = document.querySelector("title");
      siteTitle.innerText = this.sTitle;

      const seoDescriptionD = document.querySelector("meta[name='description']");
      seoDescriptionD.setAttribute('content', this.sDescription);

      const seoTitle = document.querySelector("meta[property='og:title']");
      seoTitle.setAttribute('content', this.sSiteName);

      const seoDescription = document.querySelector("meta[property='og:description']");
      seoDescription.setAttribute('content', this.sDescription);

      const seoSiteName = document.querySelector("meta[property='og:site_name']");
      seoSiteName.setAttribute('content', this.sSiteName);

      const seoImg = document.querySelector("meta[property='og:image']");
      seoImg.setAttribute('content', this.sImage);

      const seoType = document.querySelector("meta[property='og:type']");
      seoType.setAttribute('content', this.sType);

      const seoKeywords = document.querySelector("meta[name='keywords']");
      seoKeywords.setAttribute('content', this.sKeywords);

      const seoURL = document.querySelector("meta[property='og:url']");
      seoURL.setAttribute('content', this.currentURL);
    });

    var FD = new FormData();
    FD.append("mode", "raw_sct");
    FD.append("data", '{"class":"PagesBanner","lang":"zh-TW","sw":""}');
    axios({
      method: "post",
      url: "https://nkg-web-api.japaneast.cloudapp.azure.com/Form/",
      data: FD,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      this.pagesBanner = res.data.data;
    });

    var FD2 = new FormData();
    FD2.append("mode", "raw_sct");
    FD2.append("data", '{"class":"HciService","lang":"zh-TW","sw":""}');
    axios({
      method: "post",
      url: "https://nkg-web-api.japaneast.cloudapp.azure.com/Form/",
      data: FD2,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      this.serviceList = res.data.data;
      console.log("this.serviceList",this.serviceList);
    });

    var FD3 = new FormData();
    FD3.append("mode", "raw_sct");
    FD3.append("data", '{"class":"ServiceResult","lang":"zh-TW","sw":""}');
    axios({
      method: "post",
      url: "https://nkg-web-api.japaneast.cloudapp.azure.com/Form/",
      data: FD3,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      this.serviceResult = res.data.data;
      console.log("this.serviceResult",this.serviceResult);
      //算年份
      let DateTmp = [];
      this.serviceResult.forEach((v, k) => {
        DateTmp[k] = v.Y.slice(0, 4);
        console.log("DateTmp[k]========>",DateTmp[k]);
      });
      console.log("DateTmp========>",DateTmp);
      this.serviceResult_Y = DateTmp.filter(
        (va, vb) => DateTmp.indexOf(va) === vb
      );
      console.log("this.serviceResult_Y========>",this.serviceResult_Y);
      console.log("this.serviceResult_Y[0]========>",this.serviceResult_Y[0]);
      this.serviceResultAll = this.serviceResult;
      this.getYear(this.serviceResult_Y[0]);
    });
  },
  mounted(){
    const seoTitle = document.querySelector("meta[property='og:title']");
    seoTitle.setAttribute('content', "我們的服務 | Hci財團法人許潮英社會福利慈善事業基金會");

    const seoDescription = document.querySelector("meta[property='og:description']");
    seoDescription.setAttribute('content', "");

    const seoImg = document.querySelector("meta[property='og:image']");
    seoImg.setAttribute('content', "https://kinpoweb.blob.core.windows.net/kinpo21/HciBanner_Img_zh-TW_head-banner.jpg");

    const seoURL = document.querySelector("meta[property='og:url']");
    seoURL.setAttribute('content', "https://www.hci.org.tw/zh-TW/service");
  },
  methods: {
    reRender(){ //強制重新渲染
      this.serviceResult_Y.reverse();
    },
    getYear(getYear) {
      this.serviceResult = this.serviceResultAll.filter(
        (item) => item.Y.slice(0, 4) == getYear
      );
      this.activetab = getYear;
      console.log("this.activetab = getYear ========>",this.activetab);
    },
  },
  computed: {
    serviceResult2() {
      this.reRender();
      return [...this.serviceResultAll].filter(
        (x) => x.Y.slice(0, 4) == this.activetab
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 576px) {
  .px-mobile-0 {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
