<template>
  <div class="card custom-border">
    <div class="imgbox">
      <slot name="image"></slot>
    </div>
    <div class="card-img-overlay">
      <slot name="text"></slot>
    </div>
    <slot name="info"></slot>
  </div>
</template>
