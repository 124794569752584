import axios from "axios";
import { ref, reactive } from "vue";

export function Fetchs(API_Class="") {
    const isLoad = ref(false);
    const FetchData = reactive({data:[]});
    const errorMsg = ref("");

    const FD = new FormData();
    FD.append("mode", "raw_sct");
    FD.append("data", '{"class": "'+ API_Class +'","lang":"zh-TW","sw":""}');
    console.log("API_Class",API_Class);
    axios({
        method: "post",
        url: "https://nkg-web-api.japaneast.cloudapp.azure.com/Form/",
        data: FD,
        headers: { "Content-Type": "multipart/form-data" },
    })
    .then((res)=>{
        isLoad.value = true;
        FetchData.data = res.data;
        console.log("FetchData.data",FetchData.data);
        console.log("FetchData.data.data[0].Img=====>",FetchData.data.data[0].Img);
    })
    .catch((error)=>{
        errorMsg.value = error.request.statusText;
        console.log("errorMsg.value===>",errorMsg.value);
    });

    return {
        isLoad, FetchData, errorMsg
    }
}