<template>
  <div class="banner__block">
    <div class="bannerbox">
      <div class="bannerbox__inner">
        <slot name="image"></slot>
        <div class="banner__overlay">
          <div class="banner__content container">
            <slot name="operate"></slot>
            <slot name="title"></slot>
            <slot name="desc"></slot>
            <img
              src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/icon-scrolldown-yellow.svg"
              class="icon-scrolldown"
            />
          </div>
        </div>
      </div>
    </div>
    <slot name="mark"></slot>
  </div>
</template>
