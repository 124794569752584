<template>
  <div id="top" ref="top"></div>
  <Header />
  <main>
    <transition name="fade">
      <router-view />
    </transition>
  </main>
  <Footer />
  <a href="#top" class="scrolltop" v-smooth-scroll>
    <img
      class="img-fluid"
      src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/icon-arrow-up-white.svg"
    />
  </a>
</template>

<script>
// import { inject, ref } from "vue";
import { onMounted } from "vue";
import device from "current-device";
import AOS from "aos";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  setup() {
    onMounted(() => {
      console.log(device.desktop());
      AOS.init({
        disable : 'tablet',
        delay : 0 ,
      });
    });
    return {};
  },
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";

body, div, p, span,
h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  font-family: 'Noto Sans TC', 'Roboto', sans-serif!important;
}
</style>