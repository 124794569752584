<template>
  <Splide :options="options">
    <template v-for="item in cardList" :key="item.ID">
      <SplideSlide v-if="item.PinningIndex == 1">
        <router-link :to="'/' + this.$route.params.lang + `/news/${item.SeoUrl}`" class="hover-effect-more" @click="clickRouterTop">
          <card-vertical>
            <template #image>
              <div class="imgbox__inner imgbox--ratio-10x7">
                <div class="imgbox__image" :style="{backgroundImage: 'url('+ item.HomeImg +')'}"></div>
              </div>
            </template>
            <template #text>
              <h3 class="card-title single-ellipsis">{{ item.Title }}</h3>
              <p class="card-text multiline-ellipsis">{{ item.SubTitle }}</p>
            </template>
            <template #info>
              <div class="card-info"><span class="card-type"><span class="deco-border"></span>{{ item.NewsClass }}</span><time class="card-date font-enUS">{{ $filters.dateFormat(item.Date) }}</time></div>
            </template>
          </card-vertical>
        </router-link>
      </SplideSlide>
    </template>
  </Splide>
</template>
<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import { defineComponent } from 'vue';

import CardVertical from "@/components/CardVertical.vue";

export default defineComponent( {
  components: {
    Splide,
    SplideSlide,
    CardVertical,
  },
  props: ['cardList'],
  data() {
    return {
    };
  },
  methods: {
    clickRouterTop(){
      //chrome
      document.body.scrollTop = 0
      //firefox
      document.documentElement.scrollTop = 0
      //safari
      window.pageYOffset = 0
    },
  },
  setup() {
    const options = {
      perPage: 3,
      gap: '1rem',
      width: '1200px',
      // rewind: true,
      arrows: false,
      drag: false,
      padding: { left: '0', right: '.6rem' },
      fixedWidth: '350px',
      breakpoints: {
        1080: {
          perPage: 1,
          drag: 'free',
          arrows: false,
          width: '1000px',
          fixedWidth: '300px',
          padding: '1rem',
        },
        768: {
          perPage: 2,
          drag: 'free',
          arrows: false,
          width: '768px',
          fixedWidth: '300px',
          padding: { left: '0', right: '0' },
        },
        576: {
          perPage: 1,
          padding: '1.6rem',
          drag: 'free',
          arrows: false,
          fixedWidth: '340px',
        },
        375: {
          perPage: 1,
          padding: '1.6rem',
          drag: 'free',
          arrows: false,
          fixedWidth: '300px',
        },
        320: {
          perPage: 0,
          gap: ".6rem",
          padding: { left: '1rem', right: '2rem' },
          fixedWidth: '260px',
        },
      },
    };

    return { options };
  },
} );
</script>

<style lang="scss">
.home__container .card-img-overlay {
  top: 21.6rem!important;
  @media (max-width:1080px) {
    top: 17.4rem!important;
  }
  @media (max-width:576px) {
    top: 20.4rem!important;
  }
  @media (max-width:428px) {
    top: 20.9rem!important;
  }
  @media (max-width:375px) {
    top: 16.9rem!important;
  }
}

.home__container a.hover-effect-more:hover .card-img-overlay {
  top: 18.6rem!important;
  @media (max-width:1080px) {
    top: 17.4rem!important;
  }
  @media (max-width:576px) {
    top: 20.4rem!important;
  }
  @media (max-width:428px) {
    top: 20.9rem!important;
  }
  @media (max-width:375px) {
    top: 16.9rem!important;
  }
}

.home__container .sec__content .splide__pagination {
  display: none;
}

</style>
