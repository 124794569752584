<template>
  <div class="feedback-overlay" @click="closeModal">
    <div class="feedback-block">
      <img
        src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/icon-heart.svg"
        class="deco heart"
      />
      <img
        src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/icon-star.svg"
        class="deco star"
      />

      <div class="feedback-close"><i class="icon icon-close"></i></div>
      <div class="feedback-text">
        <slot name="text"></slot>
        <a class="btn btn-pill btn-primary px-5" @click="close">關閉</a>
      </div>

<div class="d-none d-md-block">
      <svg id="bg-lg" viewBox="0 0 713 458" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_dd)">
          <path
            d="M127.059 52.3742C313.088 -9.46066 418.311 -2.18883 578.015 60.9412C737.719 124.071 741.841 403.313 597.188 434.717C452.535 466.122 390.423 428.95 314.072 413.006C229.208 395.286 135.9 414.384 55.8555 350.854C-18.7496 291.641 -8.03854 97.2797 127.059 52.3742Z"
            fill="white"
          />
        </g>
        <defs>
          <filter
            id="filter0_dd"
            x="0.781738"
            y="0.608093"
            width="712.121"
            height="457.331"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="4" dy="4" />
            <feGaussianBlur stdDeviation="3" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feMorphology
              radius="5"
              operator="dilate"
              in="SourceAlpha"
              result="effect2_dropShadow"
            />
            <feOffset dx="1" dy="1" />
            <feGaussianBlur stdDeviation="2.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
            />
            <feBlend
              mode="normal"
              in2="effect1_dropShadow"
              result="effect2_dropShadow"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect2_dropShadow"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
</div>

<div class="d-block d-md-none">
  <svg id="bg-sm" width="352" height="454" viewBox="0 0 352 454" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g filter="url(#filter0_dd_1808_13161)">
  <path d="M44.7934 77.0849C-4.70444 191.065 -0.539842 256.907 45.4382 358.533C91.4163 460.158 303.942 471.797 329.735 382.626C355.528 293.455 328.039 253.521 316.897 205.399C304.513 151.911 320.268 94.3543 272.947 42.3834C228.842 -6.05563 80.7395 -5.68954 44.7934 77.0849Z" fill="white"/>
  </g>
  <defs>
  <filter id="filter0_dd_1808_13161" x="0.289551" y="0.640198" width="351.153" height="453.114" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feOffset dx="4" dy="4"/>
  <feGaussianBlur stdDeviation="3"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1808_13161"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feMorphology radius="5" operator="dilate" in="SourceAlpha" result="effect2_dropShadow_1808_13161"/>
  <feOffset dx="1" dy="1"/>
  <feGaussianBlur stdDeviation="2.5"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
  <feBlend mode="normal" in2="effect1_dropShadow_1808_13161" result="effect2_dropShadow_1808_13161"/>
  <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1808_13161" result="shape"/>
  </filter>
  </defs>
  </svg>
</div>

    </div>
  </div>
</template>

<script>
// import { ref } from "vue";

export default {
  // props: ["showFeedback"],
  // setup(props, context) {
  //   const show = ref(true);
  //   return { show, close }
  // },
  setup(props, context) {
    function closeModal() {
      context.emit("update:is-open", false);
    }
    return {
      closeModal
    }
  }
}
</script>

