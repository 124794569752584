<template>
  <div class="volunteer__container">
    <!-- BANNER -->
    <template v-for="item in pagesBanner" :key="item.ID">
      <banner-single v-if="item.Pages == 'volunteer'">
        <template #image>
          <div
            class="bannerbox__image d-none d-sm-block"
            :style="{ backgroundImage: 'url(' + item.Img + ')' }"
          ></div>
          <div
            class="bannerbox__image d-block d-sm-none"
            :style="{ backgroundImage: 'url(' + item.MImg + ')' }"
          ></div>
        </template>
        <template #title><h1 class="bannerbox__title">{{ item.Title }}</h1></template>
        <template #desc><p>{{ item.SubTitle }}</p></template>
        <template #mark>
          <img
            src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/mark-banner-primary.svg"
            class="img-fluid banner__mark"
          />
        </template>
      </banner-single>
    </template>

    <!-- MAIN -->
    <template v-for="item in pagesBanner" :key="item.ID">
    <article class="bg-primary" v-if="item.Pages == 'volunteer'">
    
      <!-- SECTION_1 -->
      <section class="sec pb-0">
          <div class="sec__content container">
            <div class="custom-content" v-html="item.Content"></div>
<!--
            <div class="video__block">
              <img
                src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/bg-body-deco.svg"
                class="deco bg-deco-start d-none d-md-block"
              />
              <div class="ratio ratio-16x9 mb-5 custom-border">
                <iframe
                  src="https://www.youtube.com/embed/qzMJfRvUsik"
                  title="YouTube video"
                  allowfullscreen
                ></iframe>
              </div>
            </div>

            <div class="row justify-content-center">
              <div class="col-lg-7">
                <h3 class="sec__subtitle">我們需要你！</h3>
                <p class="text-center">
                  您是否曾經想為這個社會多做一點事，卻因故猶豫了？<br class="d-none d-md-block" />
                  而在忙忙碌碌的生活中，是否也曾想尋找人生的意義呢？現在，您願意試著暫停腳步，<br class="d-none d-md-block" />
                  和我們的夥伴一起把愛傳遞出去嗎？<br class="d-none d-md-block" />
                  我們期待這社會每一個人多做一點，這個社會就會更好一點！許潮英慈善基金會邀請所有金仁寶集團的同仁，一起加入我們志工社的行列，期待您揮灑滿腔的熱情、分享人生的智慧、展現陽光的活力！讓好事，從自己開始；讓生命，因為你而變得不一樣！
                </p>
              </div>
              <div class="col-lg-10">
                <div class="volunteer__block">
                  <img
                    src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/bg-body-deco.svg"
                    class="deco bg-deco-end d-none d-md-block"
                  />
                  <div class="volunteer-bubble bubble">
                    <div class="bubble-overlay">
                      <div class="row align-items-center">
                        <div class="h4 col-lg-3">招募對象</div>
                        <div class="col-lg-9 ps-lg-5 text-center text-md-start">
                          認同基金會理念且具有奉獻服務的熱忱及願意積極學習的金仁寶集團同仁。
                        </div>
                      </div>
                    </div>
                    <img
                      src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/bg-volunteer-bubble-1-lg.svg"
                      class="bubble img-fluid d-none d-md-block"
                    />
                    <img
                      src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/bg-volunteer-bubble-1-sm.svg"
                      class="bubble w-100 img-fluid d-block d-md-none"
                    />
                  </div>

                  <div class="volunteer-bubble bubble">
                    <div class="bubble-overlay mb-4">
                      <div class="row align-items-center">
                        <div class="h4 col-lg-3">服務內容</div>
                        <div class="col-lg-9 ps-lg-5 text-center text-md-start">
                          志工社會不定期舉辦各種服務活動，像是偏鄉小學暑期夏令營、地球日淨灘活動等，只要您願意，歡迎參與我們任何的活動。
                        </div>
                      </div>
                    </div>
                    <img
                      src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/bg-volunteer-bubble-2-lg.svg"
                      class="img-fluid d-none d-lg-block"
                    />
                    <img
                      src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/bg-volunteer-bubble-2-sm.svg"
                      class="img-fluid w-100 d-block d-lg-none"
                    />
                  </div>
                </div>

                <div class="text-secondary my-4 my-md-5">
                  <div class="row justify-content-center">
                    <div class="col-md-8 text-center">
                      <h3 class="font-weight-bold">誠摯地邀請你加入我們！</h3>
                      <p class="mb-0">如果有任何疑問的話，歡迎隨時<br class="d-block d-md-none" />來信與我們聯繫喔！</p>
                    </div>
                  </div>
                  <hr style="width: 80%;height: 1px;opacity: .6;margin: 0.4rem auto 0.8rem;" />
                  <div class="row justify-content-center">
                    <h3 class="font-weight-bold text-center d-block d-md-none">志工社聯絡資訊</h3>
                    <div class="col-xl-7 col-lg-8 col-md-9 d-flex justify-content-md-between flex-column flex-md-row text-center" style="font-size:1.2rem;white-space: nowrap;">
                      <span>電話：<span class="font-enUS">0986-598-698</span> 王小姐</span>
                      <span
                        ><a
                          href="mailto:hcilove@hci.org.tw"
                          style="color: #489C82;white-space: nowrap;"
                          class="font-enUS"
                          >Email：<span style="text-decoration:underline;">hcilove@hci.org.tw</span></a
                        ></span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
-->
          </div>
      </section>

      <!-- SECTION_2 -->
      <section id="volunteer-form" class="sec bg-secondary pt-0 position-relative">
      <img src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/bg_volunteer_form.png" class="w-100 position-absolute" style="top: 0;left: 0;" />
        <div class="sec__content container" v-if="item.Pages == 'volunteer'">
            <div class="row justify-content-center">
              <div class="col-lg-10">
                <div class="form__block">
                  <img
                    src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/bg-body-deco.svg"
                    class="deco bg-deco-start d-none d-md-block"
                  />
                  <Form id="volunteerForm"
                    @submit="handleSubmit"
                    :validation-schema="schema"
                    v-slot="{ errors }"
                    ref="myForm"
                  >
                    <div class="form__card bg-middle shadow">
                      <h2 class="form__title text-secondary">志工社報名處</h2>
                      <div class="mb-3 row">
                        <label
                          for="inputName"
                          class="col-md-3 col-form-label label-title"
                          >姓名</label
                        >
                        <div class="col-md-9">
                          <Field
                            id="inputName"
                            name="inputName"
                            type="text"
                            class="form-control"
                            :class="{ 'is-invalid': errors.inputName }"
                            placeholder="請輸入名稱"
                            v-model="volunteerFormData.Name"
                          />
                          <ErrorMessage name="inputName" class="text-danger ps-4" />
                        </div>
                      </div>

                      <div class="mb-3 row align-items-center">
                        <label
                          for="inputSex"
                          class="col-md-3 col-form-label label-title"
                          >性別</label
                        >
                        <div class="col-md-9 d-flex justify-content-md-start justify-content-center ps-4">
                          <div
                            class="form-check form-check-inline col-4 ps-4"
                            v-for="(item, index) in sex"
                            :key="index"
                          >
                            <Field
                              class="form-check-input option-input radio"
                              type="radio"
                              name="inputSex"
                              :id="'inputSex[' + index + ']'"
                              :value="item"
                              v-model="volunteerFormData.Sex"
                            />
                            <label
                              class="form-check-label"
                              :for="'inputSex[' + index + ']'"
                              >{{ item }}</label
                            >
                          </div>
                        </div>
                        <ErrorMessage v-if="submittedShow" name="inputSex" class="text-danger offset-sm-3" style="padding-left: 36px;" />
                      </div>

                      <div class="mb-3 row">
                        <label
                          for="inputEmail"
                          class="col-md-3 col-form-label label-title"
                          >E-mail</label
                        >
                        <div class="col-md-9">
                          <Field
                            id="inputEmail"
                            name="inputEmail"
                            type="email"
                            class="form-control"
                            :class="{ 'is-invalid': errors.inputEmail }"
                            placeholder="請輸入email"
                            v-model="volunteerFormData.Email"
                          />
                          <ErrorMessage
                            name="inputEmail"
                            class="text-danger ps-4"
                          />
                        </div>
                      </div>

                      <!-- <div class="mb-3 row">
                        <label
                          for="inputCom"
                          class="col-md-3 col-form-label label-title"
                          >公司</label
                        >
                        <div class="col-md-9">
                          <select
                            class="form-select w-100"
                            v-model="volunteerFormData.Com"
                          >
                            <option disabled value="">請選取公司名稱</option>
                            <option
                              v-for="(item, index) in com"
                              :key="index"
                              :value="index"
                            >
                              {{ item }}
                            </option>
                          </select>
                        </div>
                      </div> -->

                      <div class="mb-3 row">
                        <label
                          for="inputCom"
                          class="col-md-3 col-form-label label-title"
                          >公司</label
                        >
                        <div class="col-md-9">
                          <div class="select__wrap">
                            <div class="select__bar" @click="isOpen = !isOpen">{{ volunteerFormData.Com }}</div>
                            <div class="select__options" v-if="isOpen">
                              <button v-for="(item, index) in com" :key="index" @click="getV($event), (isOpen = !isOpen)" :value="item.ValA" name="inputCom">{{ item.ValB }}</button>
                            </div>
                          </div>
                          <Field v-model="volunteerFormData.Com" as="select" name="inputCom" class="d-none">
                            <option v-for="(item, index) in com" :key="index" :value="item"></option>
                          </Field>
                          <ErrorMessage
                            name="inputCom"
                            class="text-danger ps-4"
                          />
                        </div>
                      </div>

                      <div class="mb-3 row">
                        <label
                          for="inputTeam"
                          class="col-md-3 col-form-label label-title"
                          >部門</label
                        >
                        <div class="col-md-9">
                          <Field
                            id="inputTeam"
                            name="inputTeam"
                            type="text"
                            class="form-control"
                            :class="{ 'is-invalid': errors.inputTeam }"
                            placeholder="請輸入公司名稱"
                            v-model="volunteerFormData.Team"
                          />
                          <ErrorMessage name="inputTeam" class="text-danger ps-4" />
                        </div>
                      </div>

                      <div class="mb-3 row">
                        <label
                          for="inputVID"
                          class="col-md-3 col-form-label label-title"
                          >工號</label
                        >
                        <div class="col-md-9">
                          <Field
                            id="inputVID"
                            name="inputVID"
                            type="text"
                            class="form-control"
                            :class="{ 'is-invalid': errors.inputVID }"
                            placeholder="請輸入工號"
                            v-model="volunteerFormData.VID"
                          />
                          <ErrorMessage name="inputVID" class="text-danger ps-4" />
                        </div>
                      </div>

                      <div class="mb-3 row">
                        <label
                          for="inputTelNo"
                          class="col-md-3 col-form-label label-title"
                          >公司分機</label
                        >
                        <div class="col-md-9">
                          <Field
                            id="inputTelNo"
                            name="inputTelNo"
                            type="text"
                            class="form-control"
                            :class="{ 'is-invalid': errors.inputTelNo }"
                            placeholder="請輸入公司電話及分機"
                            v-model="volunteerFormData.TelNo"
                          />
                          <ErrorMessage
                            name="inputTelNo"
                            class="text-danger ps-4"
                          />
                        </div>
                      </div>

                      <div class="mb-3 row">
                        <label
                          for="inputPhone"
                          class="col-md-3 col-form-label label-title"
                          >聯絡電話</label
                        >
                        <div class="col-md-9">
                          <Field
                            id="inputPhone"
                            name="inputPhone"
                            type="tel"
                            class="form-control"
                            :class="{ 'is-invalid': errors.inputPhone }"
                            placeholder="請輸入電話"
                            v-model="volunteerFormData.Phone"
                          />
                          <ErrorMessage
                            name="inputPhone"
                            class="text-danger ps-4"
                          />
                        </div>
                      </div>

                      <div class="row align-items-center">
                        <label
                          for="inputPassword"
                          class="col-md-3 col-form-label label-title"
                          >可服務時間</label
                        >
                        <div class="col-md-9 d-flex justify-content-between flex-320-column ps-4">
                          <div
                            class="form-check form-check-inline"
                            v-for="(item, index) in serviceHours"
                            :key="index"
                          >
                            <Field
                              class="form-check-input option-input radio"
                              type="radio"
                              name="inputServiceHours"
                              :id="'ckServiceHours[' + index + ']'"
                              :value="item"
                              v-model="volunteerFormData.ServiceHours"
                            />
                            <label
                              class="form-check-label font-enUS"
                              :for="'ckServiceHours[' + index + ']'"
                              >{{ item }}</label
                            >
                          </div>
                        </div>
                        <ErrorMessage v-if="submittedShow" name="inputServiceHours" class="text-danger offset-sm-3" style="padding-left: 36px;" />
                      </div>

                      <div class="mb-3 row align-items-center">
                        <label
                          for="inputPassword"
                          class="col-md-3 col-form-label label-title"
                          >方便聯繫時間</label
                        >
                        <div class="col-md-9 d-flex justify-content-between flex-column flex-md-row ps-4">
                          <div
                            class="form-check form-check-inline"
                            v-for="(item, index) in contactTime"
                            :key="index"
                          >
                            <Field
                              class="form-check-input option-input radio"
                              type="radio"
                              name="inputContactTime"
                              :id="'ckContactTime[' + index + ']'"
                              :value="item"
                              v-model="volunteerFormData.ContactTime"
                            />
                            <label
                              class="form-check-label font-enUS"
                              :for="'ckContactTime[' + index + ']'"
                              >{{ item }}</label
                            >
                          </div>
                        </div>
                        <ErrorMessage v-if="submittedShow" name="inputContactTime" class="text-danger offset-sm-3" style="padding-left: 36px;" />
                      </div>

                      <!-- <div class="mb-3 row">
                        <label
                          for="inputPassword"
                          class="col-md-3 col-form-label label-title"
                          >驗證碼</label
                        >
                        <div class="col-md-9">
                          <div class="input-group">
                            <input
                              class="form-control"
                              type="text"
                              id=""
                              name="time"
                              value=""
                              placeholder="請輸入驗證碼"
                            />
                            <img
                              src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/v-code.jpg"
                              class="verify-code"
                            />
                          </div>
                        </div>
                      </div> -->

                    </div>

                    <div class="d-flex justify-content-center mt-5">
                      <div class="col-lg-6 d-flex justify-content-center">
                        <!-- <input
                          type="submit"
                          class="btn btn-pill btn-light w-100 mx-3"
                          value="清除"
                        /> -->
                        <input
                          type="submit"
                          class="btn btn-pill btn-light mx-3"
                          style="line-height: 0.7;"
                          value="送出"
                          @click="showRadioMsg"
                        />
                      </div>
                    </div>
                  </Form>
                  <modal-feedback v-if="isModalOpen" v-model:is-open="isModalOpen">
                    <template #text>
                      <h3>報名資料已送出！</h3>
                      <p>我們已經收到您的報名表單，後續若有相關志工社訊息<br>會再與您聯繫，歡迎您的加入!</p>
                    </template>
                  </modal-feedback>
                </div>
              </div>
            </div>
        </div>
      </section>
    </article>
    </template>
  </div>
</template>

<script>
import axios from "axios";

import BannerSingle from "@/components/BannerSingle.vue";
import ModalFeedback from "@/components/ModalFeedback.vue";

import { Field, Form, ErrorMessage, configure } from "vee-validate";
import * as Yup from "yup";

configure({
  validateOnBlur: true, // controls if `blur` events should trigger validation with `handleChange` handler
  validateOnChange: true, // controls if `change` events should trigger validation with `handleChange` handler
  validateOnInput: false, // controls if `input` events should trigger validation with `handleChange` handler
  validateOnModelUpdate: true, // controls if `update:modelValue` events should trigger validation with `handleChange` handler
});

export default {
  components: {
    BannerSingle,
    Field,
    Form,
    ErrorMessage,
    ModalFeedback
  },
  data() {
    return {
      seoData: null,
      sTitle: null,
      sDescription: null,
      sKeywords: null,
      sSiteName: null,
      sImage: null,
      sType: null,
      currentURL: null,
      showFeedback: false,
      submittedShow: false,
      isModalOpen: false,
      isOpen: false,
      pagesBanner: [],
      comToEmailTmp:[],
      volunteerFormData: [
        {
          Name: "",
          Sex: "",
          Email: "",
          Com: "請選取公司名稱",
          Team: "",
          VID: "",
          TelNo: "",
          Phone: "",
          ServiceHours: "",
          ContactTime: "",
        },
      ],
      com: [],
      sex: [],
      serviceHours: [],
      contactTime: [],
      schema: Yup.object().shape({
        inputName: Yup.string().required("姓名為必填欄位").label("姓名"),
        inputSex: Yup.string().required("性別為必填欄位").oneOf(["男性","女性"], '性別為必填欄位').label("性別"),
        inputEmail: Yup.string().email("需為有效Email").required("Email為必填欄位").label("Email"),
        inputCom: Yup.string().required("公司為必填欄位").oneOf(["仁寶電腦工業股份有限公司","金寶電子股份有限公司","泰金寶電通股份有限公司","通寶半導體設計","康舒科技股份有限公司","三緯國際立體列印科技股份有限公司",], '公司為必填欄位').label("公司"),
        inputTeam: Yup.string().required("部門為必填欄位").label("部門"),
        inputVID: Yup.string().required("工號為必填欄位").label("工號"),
        inputTelNo: Yup.number().required("公司分機為必填欄位").label("公司分機"),
        inputPhone: Yup.number().positive().integer().required("聯絡電話為必填欄位").label("聯絡電話"),
        inputServiceHours: Yup.string().required("時間為必填欄位").oneOf(["平日","假日","皆可"], '時間為必填欄位').label("可服務時間"),
        inputContactTime: Yup.string().required("時間為必填欄位").oneOf(["9:00-12:00","13:00-17:00","皆可"], '時間為必填欄位').label("方便聯絡時間"),
      }),
      sendServer: "https://event.xyzprinting.com/Tp/SendMAIL/",
      //sendEmail: "",
      sendName: "hci website",
      sendTrigger: "hci_volunteer_TW",
    };
  },
  created() {
    var vm = this;
    this.volunteerFormData.Com = "請選取公司名稱";

    var seoFD = new FormData();
    seoFD.append("mode", "raw_sct");
    seoFD.append("data", '{"class":"HciSEO","lang":"zh-TW"}');
    axios({
      method: "post",
      url: "https://nkg-web-api.japaneast.cloudapp.azure.com/Form/",
      data: seoFD,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      const currtUrlName = this.$route.name.toLowerCase();
      this.seoData = res.data.data.filter(item => (item.SetPage == currtUrlName));
      this.sTitle = this.seoData[0].title;
      this.sDescription= this.seoData[0].description;
      this.sKeywords = this.seoData[0].keywords;
      this.sSiteName = this.seoData[0].site_name;
      this.sImage = this.seoData[0].image;
      this.sType = this.seoData[0].type;
      this.currentURL = window.location.href;
      
      console.log("this.currentURL=====>", this.currentURL)

      const siteTitle = document.querySelector("title");
      siteTitle.innerText = this.sTitle;

      const seoDescriptionD = document.querySelector("meta[name='description']");
      seoDescriptionD.setAttribute('content', this.sDescription);

      const seoTitle = document.querySelector("meta[property='og:title']");
      seoTitle.setAttribute('content', this.sSiteName);

      const seoDescription = document.querySelector("meta[property='og:description']");
      seoDescription.setAttribute('content', this.sDescription);

      const seoSiteName = document.querySelector("meta[property='og:site_name']");
      seoSiteName.setAttribute('content', this.sSiteName);

      const seoImg = document.querySelector("meta[property='og:image']");
      seoImg.setAttribute('content', this.sImage);

      const seoType = document.querySelector("meta[property='og:type']");
      seoType.setAttribute('content', this.sType);

      const seoKeywords = document.querySelector("meta[name='keywords']");
      seoKeywords.setAttribute('content', this.sKeywords);

      const seoURL = document.querySelector("meta[property='og:url']");
      seoURL.setAttribute('content', this.currentURL);
    });

    var FD = new FormData();
    FD.append("mode", "raw_sct");
    FD.append("data", '{"class":"PagesBanner","lang":"zh-TW","sw":""}');
    axios({
      method: "post",
      url: "https://nkg-web-api.japaneast.cloudapp.azure.com/Form/",
      data: FD,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      this.pagesBanner = res.data.data;
    });
    
    var FD2 = new FormData();
    FD2.append("mode", "raw_sct");
    FD2.append("data", '{"class":"Volunteer","lang":"zh-TW"}');
    axios({
      method: "post",
      url: "https://nkg-web-api.japaneast.cloudapp.azure.com/Form/",
      data: FD2,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      Object.keys(res.data.Com).forEach(function(v){
        if(res.data.Com[v].ValA!==undefined){
          vm.comToEmailTmp[res.data.Com[v].ValA] = res.data.Com[v].ValC;
        }
      }); 
      this.com = res.data.Com;
      this.sex = res.data.Sex;
      this.serviceHours = res.data.ServiceHours;
      this.contactTime = res.data.ContactTime;
    });
  },
  mounted(){
    const seoTitle = document.querySelector("meta[property='og:title']");
    seoTitle.setAttribute('content', "愛心志工社 | Hci財團法人許潮英社會福利慈善事業基金會");

    const seoDescription = document.querySelector("meta[property='og:description']");
    seoDescription.setAttribute('content', "");

    const seoImg = document.querySelector("meta[property='og:image']");
    seoImg.setAttribute('content', "https://kinpoweb.blob.core.windows.net/kinpo21/HciBanner_Img_zh-TW_head-banner.jpg");

    const seoURL = document.querySelector("meta[property='og:url']");
    seoURL.setAttribute('content', "https://www.hci.org.tw/zh-TW/volunteer");
  },
  methods: {
		getV(event) {
			this.volunteerFormData.Com = event.target.value;
		},
    showRadioMsg(){
      this.submittedShow = true;
    },
    handleReset() {
      if(this.$refs.myForm.length){
        this.$refs.myForm[0].resetForm();
      } else {
        this.$refs.myForm.resetForm();
      }
      this.volunteerFormData = [];
      this.volunteerFormData.Com = "請選取公司名稱";
    },
    handleSubmit() {
      var formData = new FormData();

      formData.append("Name", this.volunteerFormData.Name);
      formData.append("Sex", this.volunteerFormData.Sex);
      formData.append("Email", this.volunteerFormData.Email);
      formData.append("Com", this.volunteerFormData.Com);
      formData.append("Team", this.volunteerFormData.Team);
      formData.append("VID", this.volunteerFormData.VID);
      formData.append("TelNo", this.volunteerFormData.TelNo);
      formData.append("Phone", this.volunteerFormData.Phone);
      formData.append("ServiceHours", this.volunteerFormData.ServiceHours);
      formData.append("ContactTime", this.volunteerFormData.ContactTime);

      formData.append("mode", "add");

      formData.append(
        "data",
        "{" +
          '"class":"Volunteer",' +
          '"lang":"zh-TW",' +
          '"Name":"' +
          this.volunteerFormData.Name +
          '",' +
          '"Sex":"' +
          this.volunteerFormData.Sex +
          '",' +
          '"Email":"' +
          this.volunteerFormData.Email +
          '",' +
          '"Com":"' +
          this.volunteerFormData.Com +
          '",' +
          '"Team":"' +
          this.volunteerFormData.Team +
          '",' +
          '"VID":"' +
          this.volunteerFormData.VID +
          '",' +
          '"TelNo":"' +
          this.volunteerFormData.TelNo +
          '",' +
          '"Phone":"' +
          this.volunteerFormData.Phone +
          '",' +
          '"ServiceHours":"' +
          this.volunteerFormData.ServiceHours +
          '",' +
          '"ContactTime":"' +
          this.volunteerFormData.ContactTime +
          '",' +
          '"send_server":"' +
          this.sendServer +
          '",' +
          '"send_email":"' +
          this.comToEmailTmp[this.volunteerFormData.Com] +
          '",' +
          '"send_name":"' +
          this.sendName +
          '",' +
          '"send_trigger":"' +
          this.sendTrigger +
          '",' +
          '"RandCode":"' +
          Math.random() +
          '"}'
      );

      axios({
        method: "post",
        url: "https://nkg-web-api.japaneast.cloudapp.azure.com/Form/",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      }).then((res) => {
        console.log("[volunteerFormData]" + res);

        this.isModalOpen = true;
        this.handleReset();

        console.log("姓名", this.volunteerFormData.Name);
        console.log("性別", this.volunteerFormData.Sex);
        console.log("E-mail", this.volunteerFormData.Email);
        console.log("公司", this.volunteerFormData.Com);
        console.log("部門", this.volunteerFormData.Team);
        console.log("工號", this.volunteerFormData.VID);
        console.log("公司分機", this.volunteerFormData.TelNo);
        console.log("聯絡電話", this.volunteerFormData.Phone);
        console.log("服務時間", this.volunteerFormData.ServiceHours);
        console.log("方便聯絡時間", this.volunteerFormData.ContactTime);
    });
    }
  }
}
</script>

<style lang="scss">
@media (max-width:320px) {
  .flex-320-column {
    flex-direction: column;
  }
}

</style>