<template>
  <div class="index-banner">
    <Splide
      :options="options"
    >
      <template v-for="(item,index) in bannerList" :key="item.ID">
        <SplideSlide>
          <div class="banner-wrap youtube">
            <iframe
              v-if="item.YoutubeLink !== ''"
              class="banner__video"
              id=""
              frameborder="0"
              allowfullscreen="1"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              title="YouTube video player"
              width="640"
              height="360"
              :src="
                item.YoutubeLink +
                '?autoplay=1&mute=1&loop=0&color=white&controls=0&modestbranding=1&playsinline=1&rel=0&enablejsapi=1'"
            >
            </iframe>

            <div class="bannerbox">
              <div class="bannerbox__inner">
                <div class="bannerbox__image d-none d-sm-block" :style="{ backgroundImage: 'url(' + item.Img + ')' }"></div>
                <div class="bannerbox__image d-block d-sm-none" :style="{ backgroundImage: 'url(' + item.MImg + ')' }"></div>
              </div>
            </div>
            <div class="slide-caption">
              <div class="container">
                <div class="row slide-text">
                  <div class="col-11 col-md-8 col-lg-6 p-3 p-md-0">
                    <h1 class="h2" v-if="index==0" v-html="item.Title"></h1>
                    <div class="h2" v-else v-html="item.Title"></div>
                    <p class="sub-text">{{ item.SubTitle }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SplideSlide>
      </template>
    </Splide>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Splide, SplideSlide } from "@splidejs/vue-splide";

import "@splidejs/splide/dist/css/themes/splide-skyblue.min.css";
import { Video } from "@splidejs/splide-extension-video";
import "@splidejs/splide-extension-video/dist/css/splide-extension-video.min.css";

export default defineComponent({
  components: {
    Splide,
    SplideSlide,
  },
  props: ["bannerList"],
  data() {
    return {
      splideRef: this.$refs.splide,
      splideCurrentPage: 0,
      splideTotalSize: 0,
      options: {
        rewind: true,
        Speed: 7000,
        rewindSpeed: 7000,
        arrows: false, //boolean | 'slider' = true
        pagination: true, //boolean | 'slider' = true
        // perPage: "1",
        autoplay: true,
        pauseOnHover: false,
        // height: "15rem",
      },
      extensions: { Video },
    };
  },
});
</script>

<style lang="scss">
.splide__slide {
  opacity: 1!important;
}


.banner-wrap.youtube {
  position: relative;
	overflow: hidden;
	width: 100%;
	/* Keep it the right aspect-ratio */
	//aspect-ratio: 1440/450;
	/* No clicking/hover effects */
	pointer-events: none;
  iframe {
    position: absolute;
    z-index: 9;
		/* Extend it beyond the viewport... */
		width: 300%;
		//height: 100%;
    height: 100%;
		/* ...and bring it back again */
		margin-left: -100%;
    @media (max-width:576px) {
      width: 255%;
      margin-left: -77%;
    }
  }
}

.slide-caption {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  z-index: 3;
}
.slide-text {
  color: #fff;
  font-size: 1rem;
  @media (max-width:576px) {
    justify-content: center;
  }
}
.slide-text .h2,
.slide-text .h2 p,
.slide-text .h2 p span,
.slide-text .h2 div span {
  font-size: 36px;
  font-weight: 700;
  line-height: 3.25rem;
  text-align: justify;
  @media (max-width:992px) {
    font-size: 30px;
    line-height: 3rem;
  }
  @media (max-width:320px) {
    font-size: 22px;
    line-height: 2rem;
    font-weight: 500;
  }
}
@media (max-width:320px) {
  .slide-text .sub-text {
    font-size: 14px;
  }
}
.slide-text .h2 span,
.slide-text .h2 div span {
  // display: inline-block;
  text-decoration: none !important;
  border-bottom: 2px solid $primary;
  // font-size: 18px;
  // font-weight: 400;
  // line-height: 20px;
}

.slide-title {
  text-align: center;
  font-size: 2.3rem;
  font-weight: 600;
  padding-bottom: 1rem;
  border-bottom: 1px solid #52b093;
}

.index-banner .bannerbox {
  position: relative;
  z-index: 1;
}
.index-banner .bannerbox::after {
  content: "";
  position: absolute;
  background: rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 2;
}

.bannerbox {
  &::after {
    content: "";
    background: rgba(0, 0, 0, 0.15);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    z-index: -1;
  }
}
//
.banner__video {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  height: 100%;
}
</style>
