<template>
  <div class="errorpage__container">
    <div class="container">
      <div class="d-flex justify-content-center align-items-center">
        <img src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/404.gif" class="img-fluid" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted(){
    const seoTitle = document.querySelector("meta[property='og:title']");
    seoTitle.setAttribute('content', "404 | Hci財團法人許潮英社會福利慈善事業基金會");

    const seoURL = document.querySelector("meta[property='og:url']");
    seoURL.setAttribute('content', "https://www.hci.org.tw/zh-TW/home");
  },
}
</script>