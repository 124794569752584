<template>
  <div class="table__block">
    <nav class="table__nav d-flex justify-content-center"></nav>

    <!-- <select
      class="searchbar__select form-select mb-4 d-block d-md-none"
      @change="sctClass($event)"
    >
      <option disabled value="">請選擇項目</option>
      <option :value="item" v-for="item in itemClass" :key="item.ID">
        {{ item }}
      </option>
    </select> -->
    <div class="select__wrap mb-4 d-block d-lg-none">
      <div class="select__bar" @click="isOpen = !isOpen">{{ showValue }}</div>
      <div class="select__options" v-if="isOpen">
        <button  v-for="item in itemClass" :key="item.ID" @click="(filterClass = item), (isOpen = !isOpen), showValue = item" :value="item">{{ item }}</button>
      </div>
    </div>

    <div class="table__main custom-border">
      <div class="table__inner">
        <table class="table-vertical">
          <thead>
            <tr>
              <th @click="(priority = itemName), (ASC = -1 * ASC), (sortByItems=!sortByItems)">
                項目
                <span class="btn-sort"><i class="icon icon-sort"></i></span>
              </th>
              <th>內容</th>
              <th @click="(priority = 'Date'), (ASC = -1 * ASC)">
                更新日期
                <span class="btn-sort"><i class="icon icon-sort"></i></span>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item,i) in newarray" :key="i">
              <td
                data-th="項目"
                class="text-nowrap"
                v-if="item.DonateItem !== undefined"
              >
                {{ item.DonateItem }}
              </td>
              <td
                data-th="項目"
                class="text-nowrap"
                v-if="item.HciItems !== undefined"
              >
                {{ item.HciItems }}
              </td>
              <td data-th="內容">{{ item.Title }}</td>
              <td data-th="更新日期">
                <time class="text-nowrap font-enUS">{{ $filters.dateFormatDot(item.Date) }}</time>
              </td>
              <td data-th="">
                <a :href="item.DFiles" class="btn btn-pill btn-primary" download v-if="item.DonateItem !== undefined"
                  >下載<img
                    src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/icon-download.svg"
                    class="ms-3"
                /></a>
                <a :href="item.OPFiles" class="btn btn-pill btn-primary" download v-if="item.HciItems !== undefined"
                  >下載<img
                    src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/icon-download.svg"
                    class="ms-3"
                /></a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import device from "current-device";
export default {
  components: {},
  props: ["fileList", "itemClass", "itemName"],
  data() {
    return {
      isOpen: false,
      showValue: "請選擇項目",
      filterClass: "",
      fileListAll: [],
      priority: "",
      ASC: 1,
      sortByItems: false
    };
  },
  watch: {
    fileList(newValue){
      this.fileListAll = newValue;
      this.showValue = "請選擇項目";
      this.filterClass = "";
    }
  },
  methods: {
    // sort() {
    //   this.$emit("sortItem", this.priority, this.ASC);
    // },
    // sctClass(e) {
    //   console.log('---',e.target.value, this.fileList);
    // },
  },
  computed: {
    newarray: function () {
      var vm = this;
      if(!device.desktop()){
        if(!(vm.filterClass in vm.itemClass) || vm.filterClass == "全部"){
          return vm.fileListAll;
        } else {
          return vm.fileList.filter((item) => item[vm.itemName] == vm.filterClass);
        }
      } else {
        return vm.fileList.sort( (a1, b1)=> {
          var a = a1[vm.priority];
          var b = b1[vm.priority];
          if (vm.priority === vm.itemClass) {
            if(vm.sortByItems) {
              return a1[vm.itemClass].localeCompare(b1[vm.itemClass],"zh-Hant")
            }else {
              return b1[vm.itemClass].localeCompare(a1[vm.itemClass],"zh-Hant")
            }
          }
          return a === b ? 0 : a > b ? vm.ASC * 1 : vm.ASC * -1;
        });
      }
    }
  }
};
</script>

<style lang="scss">
.btn-sort {
  display: inline-block;
  margin-bottom: -0.3rem;
  cursor: pointer;
}
.table-vertical {
  width: 100%;
  height: auto;
  border-collapse: collapse;
  @media (max-width:320px) {
    padding: .6rem;
  }
  > {
    tbody > tr:nth-of-type(even) {
      background: #fffae6;
    }
    thead > tr > th {
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;
      background: #fff;
      color: #000;
      border-bottom: 1px solid #333;
    }
    tbody > tr > td {
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      height: 88px;
      @media (max-width:992px) {
        height: auto;
      }
    }
    tbody > tr > td,
    thead > tr > th {
      padding: 1rem 2rem;
      text-align: left;
      vertical-align: middle;
      min-width: 11rem;
      @media (max-width:992px) {
        padding: .2rem 2rem;
        &:last-child {
          padding: 0;
        }
      }
    }
    tbody > tr > td:last-child {
      width: 200px;
    }
  }
}

//@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
@media only screen and (max-width: 992px) {
  table, thead, tbody, th, td, tr {
    display: block;
  }

  .table-vertical {
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
    > {
      thead > tr > td {
        border: none;
        position: relative;
        padding-left: 30%;
      }
      tbody > tr {
        padding: 0.8rem 1rem;
      }
      tbody > tr > td {
        border: none;
        position: relative;
        padding-left: 32%;
        padding-right: 0;
        &:before {
          position: absolute;
          top: 4px;
          left: 0;
          width: 32%;
          padding-right: 10px;
          white-space: nowrap;
          font-size: 20px;
          font-weight: 700;
          content: attr(data-th);
        }
      }
      tbody > tr > td:last-child {
        width: 100%;
      }
    }
  }
}
@media (max-width:375px) {
  .table-vertical > tbody > tr > td {
    padding-left: 37%;
  }
}
@media (max-width:320px) {
  .table-vertical > tbody > tr > td {
    padding-left: 37%;
  }
  .table-vertical > tbody > tr > td:before {
    font-size: 18px;
  }
}

.table-vertical .btn {
  width: 140px;
  @media (max-width:992px) {
    position: relative;
    width: 420px;
    left: 50%;
    transform: translateX(-20%);
    margin-top: .4rem;
  }
  @media (max-width:768px) {
    width: 360px;
    transform: translateX(-30%);
  }
  @media (max-width:576px) {
    transform: translateX(-25%);
  }
  @media (max-width:480px) {
    width: 310px;
    transform: translateX(-50%);
  }
  @media (max-width:375px) {
    width: 270px;
    transform: translateX(-50%);
  }
  @media (max-width:320px) {
    width: 240px;
    transform: translateX(-50%);
  }
}

@media (max-width:992px) {
  .table-vertical tbody tr {
    padding: 0.8rem 1rem;
    width: 100%;
    margin: .6rem 0;
  }
}
</style>
