<template>
  <div class="slide__block">
    <div class="bg-gradient slide-mark-start w-100">
      <img
        src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/mask-banner-secondary-gradient.png"
        class="img-fluid w-100"
      />
      <img
        src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/deco-speaker.svg"
        class="deco deco-speaker"
      />
    </div>
    <img
      src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/mark-banner-white.svg"
      class="img-fluid w-100 slide-mark-end"
    />
    <Splide
      :options="options"
      ref="splide"
      @splide:arrows:mounted="onArrowsMounted"
    >
      <SplideSlide v-for="(item, idx) in serviceList" :key="item.ID">
        <div class="row align-items-stretch align-items-center">
          <div class="col-md-6 col-lg-7 px-0 position-relative">
            <div class="bannerbox">
              <div class="bannerbox__inner box-pt-600">
                <div
                  class="bannerbox__image"
                  :style="{ backgroundImage: 'url(' + item.Img + ')' }"
                ></div>
              </div>
            </div>
            <img
              src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/mark-banner-primary.svg"
              class="img-fluid w-100 slide-mark-end d-block d-md-none"
            />
          </div>
          <div
            class="
              col-md-6 col-lg-5
              ps-md-0
              d-flex
              align-items-center
              bg-primary
            "
          >
            <div class="slide-textbox">
              <h2 class="slide-title text-secondary">
                {{ $filters.removeHtmlTag(item.Title) }}
              </h2>
              <h3 class="slide-text">{{ item.Introduction }}</h3>
              <p class="d-flex justify-content-center mt-3">
                <router-link :to="{path:'/' + this.$route.params.lang + '/service',hash:'#sec' + (idx+1)}" class="btn btn-pill btn-dark d-flex justify-content-center">
                    了解更多
                  </router-link>
              </p>
            </div>
          </div>
        </div>
      </SplideSlide>

      <template #after-track>
        <div class="custom__arrows splide__arrows">
          <button class="splide__arrow splide__arrow--prev">
            <span class="icon-prev icon-line-arrow"
              ><span></span><span></span
            ></span>
          </button>
          <button class="splide__arrow splide__arrow--next">
            <span class="icon-next icon-line-arrow"
              ><span></span><span></span
            ></span>
          </button>
        </div>

        <div class="splide__autoplay">
          {{ `${splideCurrentPage} / ${splideTotalSize}` }}
        </div>
      </template>
    </Splide>
  </div>
</template>


<script>
//import { defineComponent } from 'vue';
import { Splide, SplideSlide } from "@splidejs/vue-splide";

export default {
  components: {
    Splide,
    SplideSlide,
  },
  props: ["serviceList"],
  data() {
    return {
      splideRef: this.$refs.splide,
      splideCurrentPage: 0,
      splideTotalSize: 0,
      options: {
        type: "fade",
        autoplay: true,
        rewind: true,
        arrows: true, //boolean | 'slider' = true
        pagination: false, //boolean | 'slider' = true
        pauseOnHover: false,
      },
    };
  },
  // setup() {
  //   const options = {
  //     rewind: true,
  //     arrows: false,
  //     pagination: false, //boolean | 'slider' = true
  //   };

  //   return { options };
  // },
  methods: {
    onArrowsMounted(splide, prev, next) {
      // let totalLength = splide.Components.Slides.getLength(); //總長度
      // let currentIdex = splide.Components.Controller.getIndex() + 1; //取得目前的 index
      // this.splideCurrentPage = currentIdex;
      // this.splideTotalSize = totalLength;
      console.log(prev, next);
      //監聽改變 slides
      splide.event.on("move", () => {
        let totalLength = splide.Components.Slides.getLength(); //總長度
        let currentIdex = splide.Components.Controller.getIndex() + 1; //取得目前的 index
        this.splideCurrentPage = currentIdex;
        this.splideTotalSize = totalLength;
      });
        splide.event.on("arrows:updated", () => {
        let totalLength = splide.Components.Slides.getLength(); //總長度
        let currentIdex = splide.Components.Controller.getIndex() + 1; //取得目前的 index
        this.splideCurrentPage = currentIdex;
        this.splideTotalSize = totalLength;
      });
    },
  },

};
</script>

<style lang="scss" scoped>
.icon-line-arrow {
  position: relative;
  display: block;
  width: 160px;
  span {
    position: absolute;
    height: 2px;
    background: #fff;
    display: block;
    &:first-child {
      width: 56px;
      transition: all 2s;
    }
    &:last-child {
      position: absolute;
      width: 16px;
      transform: rotate(-45deg);
      margin-left: -2px;
      bottom: 3px;
    }
  }
}

.custom__arrows.splide__arrows {
  position: absolute;
  z-index: 99;
  width: 320px;
  top: 60%;
  left: 58%;
  transform: translate(120%, 0);
  display: flex;
  justify-content: space-between;
  @media (max-width: 1980px) {
    left: 51%;
  }
  @media (max-width: 1920px) {
    top: 68%;
    width: 320px;
  }
  @media (max-width: 1800px) {
    width: 300px;
  }
  @media (max-width: 1600px) {
    left: 50%;
    width: 365px;
    transform: translateX(65%);
  }
  @media (max-width: 1440px) {
    top: 75%;
    left: 50%;
    transform: translate(80%, 0);
    width: 320px;
  }
  @media (max-width: 1366px) {
    left: 49%;
    transform: translate(80%, 0);
  }
  @media (max-width: 1280px) {
    left: 47%;
    width: 318px;
  }
  @media (max-width: 1200px) {
    left: 47%;
    width: 300px;
  }
  @media (max-width: 1080px) {
    left: 45%;
    width: 285px;
  }
  @media (max-width: 1024px) {
    top: 80%;
    left: 42%;
    width: 294px;
  }
  @media (max-width: 992px) {
    left: 41%;
  }
  @media (max-width: 834px) {
    left: 28.5%;
  }
  @media (max-width: 768px) {
    left: 25%;
    bottom: 9%;
  }
  @media (max-width: 576px) {
    left: -16%;
    top: 82%;
  }
  @media (max-width: 428px) {
    left: -39%;
    top: 90%;
  }
  @media (max-width: 414px) {
    left: -42%;
  }
  @media (max-width: 390px) {
    left: -48%;
  }
  @media (max-width: 375px) {
    top: 89%;
    left: -52%;
  }
  @media (max-width: 360px) {
    top: 94%;
    left: -56%;
  }
  @media (max-width: 320px) {
    top: 92%;
    left: -56%;
    width: 262px;
  }
  .splide__arrow.splide__arrow--prev {
    left: 0;
  }
  .splide__arrow.splide__arrow--next {
    right: 0;
    .icon-line-arrow {
      transform: rotateY(180deg);
    }
  }
}
.splide__arrow {
  background: transparent !important;
}

.splide__autoplay {
  position: absolute;
  bottom: 39%;
  right: 20%;
  z-index: 999;
  color: #fff;
  @media (max-width: 1920px) {
    bottom: 31%;
    right: 20%;
  }
  @media (max-width: 1440px) {
    bottom: 8.5rem;
    right: 20%;
  }
  @media (max-width: 1366px) {
    right: 19.5%;
  }
  @media (max-width: 1200px) {
    right: 19%;
  }
  @media (max-width: 1024px) {
    bottom: 17%;
  }
  @media (max-width: 992px) {
    right: 19%;
  }
  @media (max-width: 834px) {
    right: 23%;
  }
  @media (max-width: 768px) {
    bottom: 13%;
    right: 23%;
  }
  @media (max-width: 576px) {
    bottom: 4.8rem;
    right: 46%;
  }
  @media (max-width: 428px) {
    bottom: 3rem;
  }
  @media (max-width: 375px) {
    bottom: 8%;
  }
  @media (max-width: 360px) {
    bottom: 5%;
  }
  @media (max-width: 320px) {
    right: 45%;
  }
}

.splide__pagination {
  bottom: 16%;
  right: 16%;
}

@media (max-width: 576px) {
  .bg-gradient.slide-mark-start {
    margin-top: -5px;
    img {
      margin-top: -10px;
    }
  }
}

.slide-textbox {
  width: 64%;
  margin: auto;
  @media (max-width: 768px) {
    width: 80%;
  }
  @media (max-width: 576px) {
    width: 90%;
    min-height: 360px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding-top: 3rem;
  }
  @media (max-width: 375px) {
    padding-top: 2rem;
  }
  @media (max-width: 320px) {
    min-height: 316px;
    padding-top: 1.4rem;
  }
  .slide-title {
    text-align: center;
    font-size: 36px;
    font-weight: 600;
    padding-bottom: 1rem;
    border-bottom: 1px solid #52b093;
    @media (max-width: 1378px) {
      font-size: 35px;
    }
    @media (max-width: 1354px) {
      font-size: 34px;
    }
    @media (max-width: 1330px) {
      font-size: 33px;
    }
    @media (max-width: 1306px) {
      font-size: 32px;
    }
    @media (max-width: 1280px) {
      font-size: 31px;
    }
    @media (max-width: 1258px) {
      font-size: 30px;
    }
    @media (max-width: 1234px) {
      font-size: 29px;
    }
    @media (max-width: 1080px) {
      font-size: 28px;
    }
    @media (max-width: 1024px) {
      font-size: 27px;
    }
    @media (max-width: 768px) {
      font-size: 24px;
    }
    @media (max-width: 576px) {
      font-size: 1.8rem;
    }
    @media (max-width: 320px) {
      font-size: 1.5rem;
    }
  }
  .slide-text {
    color: #333;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6;
    margin-bottom: 16px;
    @media (max-width: 576px) {
      margin-bottom: 0;
    }
  }
}

.box-pt-600 {
  padding-top: 1300px !important;
  @media (max-width: 1920px) {
    padding-top: 800px !important;
  }
  @media (max-width: 1440px) {
    padding-top: 600px !important;
  }
  @media (max-width: 1024px) {
    padding-top: 500px !important;
  }
  @media (max-width: 768px) {
    padding-top: 400px !important;
  }
  @media (max-width: 576px) {
    padding-top: 300px !important;
  }
  @media (max-width: 320px) {
    padding-top: 200px !important;
  }
}
.splide__slide {
  background: #f4d240;
}
//頁碼
.splide {
  height: 1300px;
  position: relative;
  @media (max-width: 1920px) {
    height: 800px;
  }
  @media (max-width: 1440px) {
    height: 600px;
  }
  @media (max-width: 1024px) {
    height: 500px;
  }
  @media (max-width: 768px) {
    height: 400px;
  }
  @media (max-width: 576px) {
    height: auto;
  }
}

.slide-mark-start {
  position: absolute;
  top: -1px;
  z-index: 9;
}
.slide-mark-end {
  position: absolute;
  bottom: 0;
  z-index: 1;
  margin-bottom: -1px;
}
</style>
