<template>
  <div class="home__container">
    <!-- BANNER -->
    <banner-slider :bannerList="bannerList"></banner-slider>
    <!-- MAIN -->
    <article>
      <!-- SECTION_1 -->
      <section class="sec bg-secondary pb-5 pb-md-1">
        <img src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/bg-secondary-gradient.png" class="img-fluid sec-deco-bottom bg-secondary-gradient">
        <h2 class="sec__title">我們的訊息</h2>
        <div class="sec__content container px-0">
          <slider-indexcard :cardList="newsList"></slider-indexcard>
          <p class="text-center mt-5 pb-2 mb-0">
            <router-link
              :to="'/' + this.$route.params.lang + '/news'"
              @click="clickRouterTop"
              class="btn btn-pill btn-light"
              >了解更多</router-link
            >
          </p>
        </div>
      </section>

      <!-- SECTION_2 -->
      <section class="sec py-0 bg-secondary-light">
        <div class="sec__content">
          <slider-p :serviceList="serviceList"></slider-p>
        </div>
      </section>

      <!-- SECTION_3 -->
      <section id="album" class="sec">
        <h2 class="sec__title">愛的塗鴉牆</h2>
        <div class="sec__content position-relative container-sm px-0 px-md-1">
          <img
            src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/deco-heart.svg"
            class="deco-heart img-fluid"
          />

          <div class="wall__block">
            <div class="wall__main">
              <!-- <infinite-scroll @infinite-scroll="loadDataFromServer"> -->
              <!-- Place the content of your page here. E.g a list of resources being fetched from a server -->
              
              <div class="text-center" style="margin-top: 30vh;" v-if="!isLoad"> 
                <LoadingAnimation />
              </div>

              <h3 v-if="errorMsg">{{ errorMsg }}</h3>
              <div class="row photo-wall" v-if="isLoad">
                <!-- <div class="col-4 p-1" v-for="item in graffitiList" :key="item.ID"> -->
                <div class="col-4 p-1" v-for="item in FetchData.data.data" :key="item.Img">
                  <img-box class="">
                    <template #image>
                      <div class="imgbox__inner imgbox--ratio-1x1 rounded-0">
                        <div
                          class="imgbox__image hover-style"
                          :style="{ backgroundImage: 'url(' + item.Img + ')' }"
                          @click="openBox(item)"
                        ></div>
                      </div>
                    </template>
                  </img-box>
                </div>
              </div>
              <!-- </infinite-scroll> -->
            </div>

            <transition name="fade">
              <light-box
                :close="closeBox"
                :imgsource="currentPic"
                v-if="lightBoxToggle"
              ></light-box>
            </transition>

          </div>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
import { useFetch } from "../composition-api";
import axios from "axios";
import BannerSlider from "@/components/BannerSlider.vue";
import SliderIndexcard from "@/components/SliderIndexcard.vue";
import SliderP from "@/components/SliderP.vue";

import LoadingAnimation from "@/components/LoadingAnimation.vue";
import ImgBox from "@/components/ImgBox.vue";
import LightBox from "@/components/LightBox.vue";

export default {
  name: "Home",
  components: {
    BannerSlider,
    SliderIndexcard,
    SliderP,
    LoadingAnimation,
    ImgBox,
    LightBox,
  },
  data() {
    return {
      seoData: null,
      sTitle: null,
      sDescription: null,
      sKeywords: null,
      sSiteName: null,
      sImage: null,
      sType: null,
      currentURL: null,
      bannerList: [],
      newsList: [],
      serviceList: [],
      graffitiList: [],
      currentPic: "",
      lightBoxToggle: false
    };
  },
  created() {
    var seoFD = new FormData();
    seoFD.append("mode", "raw_sct");
    seoFD.append("data", '{"class":"HciSEO","lang":"zh-TW"}');
    axios({
      method: "post",
      url: "https://nkg-web-api.japaneast.cloudapp.azure.com/Form/",
      data: seoFD,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      const currtUrlName = this.$route.name.toLowerCase();
      this.seoData = res.data.data.filter(item => (item.SetPage == currtUrlName));
      this.sTitle = this.seoData[0].title;
      this.sDescription= this.seoData[0].description;
      this.sKeywords = this.seoData[0].keywords;
      this.sSiteName = this.seoData[0].site_name;
      this.sImage = this.seoData[0].image;
      this.sType = this.seoData[0].type;
      this.currentURL = window.location.href;
      
      console.log("this.currentURL=====>", this.currentURL)
      console.log("this.seoData==>",this.seoData);
      console.log("this.$route==>",this.$route.name.toLowerCase());
      console.log("this.seoData.title==>",this.seoData[0].title);
      console.log("this.sTitle==>",this.sTitle);

      const siteTitle = document.querySelector("title");
      siteTitle.innerText = this.sTitle;

      const seoDescriptionD = document.querySelector("meta[name='description']");
      seoDescriptionD.setAttribute('content', this.sDescription);

      const seoTitle = document.querySelector("meta[property='og:title']");
      seoTitle.setAttribute('content', this.sSiteName);

      const seoDescription = document.querySelector("meta[property='og:description']");
      seoDescription.setAttribute('content', this.sDescription);

      const seoSiteName = document.querySelector("meta[property='og:site_name']");
      seoSiteName.setAttribute('content', this.sSiteName);

      const seoImg = document.querySelector("meta[property='og:image']");
      seoImg.setAttribute('content', this.sImage);

      const seoType = document.querySelector("meta[property='og:type']");
      seoType.setAttribute('content', this.sType);

      const seoKeywords = document.querySelector("meta[name='keywords']");
      seoKeywords.setAttribute('content', this.sKeywords);

      const seoURL = document.querySelector("meta[property='og:url']");
      seoURL.setAttribute('content', this.currentURL);
    });

    var FD = new FormData();
    FD.append("mode", "raw_sct");
    FD.append("data", '{"class":"HciBanner","lang":"zh-TW","sw":""}');
    axios({
      method: "post",
      url: "https://nkg-web-api.japaneast.cloudapp.azure.com/Form/",
      data: FD,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      this.bannerList = res.data.data;
    });

    var FD2 = new FormData();
    FD2.append("mode", "raw_sct");
    FD2.append("data", '{"class":"HciNews","lang":"zh-TW","sw":""}');
    axios({
      method: "post",
      url: "https://nkg-web-api.japaneast.cloudapp.azure.com/Form/",
      data: FD2,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      this.newsList = res.data.data;
      console.log("newsList", this.newsList);
    });

    var FD3 = new FormData();
    FD3.append("mode", "raw_sct");
    FD3.append("data", '{"class":"HciService","lang":"zh-TW","sw":""}');
    axios({
      method: "post",
      url: "https://nkg-web-api.japaneast.cloudapp.azure.com/Form/",
      data: FD3,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      this.serviceList = res.data.data;
    });

    // var FD4 = new FormData();
    // FD4.append("mode", "raw_sct");
    // FD4.append("data", '{"class":"Graffiti","lang":"zh-TW"}');
    // axios({
    //   method: "post",
    //   url: "https://nkg-web-api.japaneast.cloudapp.azure.com/Form/",
    //   data: FD4,
    //   headers: { "Content-Type": "multipart/form-data" },
    // }).then((res) => {
    //   this.graffitiList = res.data.data;
    // });
  },
  methods: {
    clickRouterTop(){
      //chrome
      document.body.scrollTop = 0
      //firefox
      document.documentElement.scrollTop = 0
      //safari
      window.pageYOffset = 0
    },
    openBox: function (d) {
      this.currentPic = d;
      this.lightBoxToggle = !this.lightBoxToggle;
    },
    closeBox: function () {
      this.lightBoxToggle = false;
    }
  },
  setup() {
    const { isLoad, FetchData, errorMsg } = useFetch("Graffiti");
    return { isLoad, FetchData, errorMsg };
  }
};
</script>

<style lang="scss" scoped>
//vue my photos
img.thumb {
  width: 270px;
  height: 180px;
  margin: 20px;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.4s ease;
}

.thumbnailfade-leave-active,
.thumbnailfade-enter-active {
  transition: all 0.4s ease;
}

.thumbnailfade-enter-active {
  transition-delay: 0.4s;
}

.thumbnailfade-enter,
.thumbnailfade-leave-to {
  opacity: 0;
}

// Loader
.preloader {
  position: absolute;
  top: 0;
  left: 0;
  background-color: hsl(348, 100%, 61%);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  overflow: hidden;
}
.loader {
  height: 4rem;
  width: 4rem;
  border: 2px solid white;
  border-right-color: transparent;
  border-top-color: transparent;
}

// Slide Fade
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
//
// Fade
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
//
// Fade slow
.fade-slow-enter-active,
.fade-slow-leave-active {
  transition: opacity 3s;
}
.fade-slow-enter,
.fade-slow-leave-to {
  opacity: 0;
}

.hover-style {
  position: relative;
  cursor: pointer;
  &:hover:before {
    content: url("https://kinpoweb.blob.core.windows.net/hci21/frontend/images/icon-enlarge.svg");
    position: absolute;
    right: 10%;
    bottom: 8%;
    z-index: 9;
  }
  &:hover:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0,0,0,.45);
  }
}
@media (max-width:576px) {
  .home__container .bg-secondary-gradient {
    height: 300px;
    bottom: 6px;
  }
}
</style>

<style lang="scss">
.home__container{
  .splide__pagination{
    bottom: 1.5rem;
    .splide__pagination__page {
      margin: 3px 0.7rem;
    }
  }
}
</style>
