<template>
	<div class="tabs__block">
    <div class="my-4">
      <Splide :key="numberkey" :options="options" @splide:click="onSplideChange" @splide:move="onSplideChange" :class="{'jc-center':isCenter}" class="switch__wrap">
        <SplideSlide v-for="(v, k) in yearList" :key="k" class="border-0">
          <div class="switch-title">
            {{ v }}
          </div>
        </SplideSlide>
      </Splide>
    </div>

    <div class="content">
      <!--<div v-if="activetab || activetab==null" class="tabcontent">-->
      <div v-if="activetab || activetab == null" class="tabcontent">
        <slot name="activetab"></slot>
      </div>
    </div>
  </div>
</template>
<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import { defineComponent, watch, ref, onMounted } from "vue";

export default defineComponent({
  components: {
    Splide,
    SplideSlide,
  },
  props: ["data_Y"],
  emits: ["getYear"],
  data() {
    return {
      activetab: "2021",
    };
  },
  methods: {
    // tabClick() {
    //   this.$emit("getYear", this.activetab);
    // },
  },
  setup(props, context) {
    const splide = ref();
    let numberkey = ref(0);
    let yearList = ref(0);
    let isCenter = ref(false);

    const options = {
      perPage: 6,
      perMove: 1,
      width: "100%",
      pagination: false,
      isNavigation: true,
      arrowPath: "M33.4,20L14,1.6c-0.8-0.7-2-0.7-2.8,0c-0.8,0.8-0.8,2.1,0,2.9L27.6,20L11.2,35.5c-0.8,0.8-0.8,2.1,0,2.9  c0.8,0.7,2,0.7,2.8,0L33.4,20z",
      breakpoints: {
        2048: {
          perPage: 6,
        },
        1400: {
          perPage: 6,
        },
        1200: {
          perPage: 5,
        },
        900: {
          perPage: 4,
        },
        500: {
          perPage: 2,
        },
      },
    }
    watch( () => props.data_Y, () => {
      listen_W();
      yearList.value = [...props.data_Y].sort((a, b) => b - a);
      context.emit("getYear", yearList.value[0]);
    })
    onMounted(() => {
      listen_W();
      yearList.value = [...props.data_Y].sort((a, b) => b - a);
      window.addEventListener('resize', listen_W);
    })
    function listen_W() {
      let nowBP = Object.keys(options.breakpoints).find(key => key >= window.innerWidth); // 判斷目前斷點
      if (window.innerWidth > Math.max(...Object.keys(options.breakpoints))) { // 螢幕寬 大於 最大斷點
        if(props.data_Y.length > options.perPage){
          options.arrows = true;
          isCenter.value = false;
        } else {
          options.perPage = props.data_Y.length;
          options.arrows = false; 
          isCenter.value = true;
        }
      } else {
        if(props.data_Y.length > options.breakpoints[nowBP].perPage){ // tab總數 是否大於目前斷點之設定
          options.breakpoints[nowBP].arrows = true;
          isCenter.value = false;
        } else {
          let wrap_W = document.querySelector('.splide__track').offsetWidth;
          options.breakpoints[nowBP].arrows = false;
          options.breakpoints[nowBP].fixedWidth = (wrap_W / options.breakpoints[nowBP].perPage) + 'px';
          isCenter.value = true;
        }
      }
      reRender();
    }
    function reRender(){ //強制重新渲染
      numberkey.value +=1;
    }
    function onSplideChange(splide) {
      context.emit("getYear", yearList.value[splide.index]);
    }

    return { 
      splide,
      options, 
      yearList,
      numberkey,
      isCenter,
      listen_W,
      onSplideChange,
      reRender
    };
  },
});
</script>

<style lang="scss">

.tab-title {
  color: #fff;
  font-size: 1.8rem;
  cursor: pointer;
}

:root,
.about__container,
.about__container #sec3,
.service__container {
  .tabs__block {
    .is-visible.is-active {
      .switch-title {
        // color: #fff !important;
        // background: rgba(0, 0, 0, .2) !important;
        color: $secondary-dark;
        background: #fff !important;
        @media (min-width: 992px) {
          &:hover {
            color: #fff !important;
            background: $secondary-light;
          }
        }
      }
    }
  }
}

.about__container #sec3,
.service__container {
  .tabs__block {
    .switch-title {
      //width: 8rem;
      color: #fff;
      background: transparent!important;
      border: 2px solid #fff;
      @media (min-width: 992px) {
        &:hover {
          color: #fff !important;
          background: $secondary-light;
          border-color: $secondary-light;
        }
      }
    }
  }
}

#event-showcase .wall__nav .switch-title,
.donate__container .tabs__block .switch-title,
.about__container #sec2 .tabs__block .switch-title {
  color: $secondary-dark;
  background: transparent!important;
  border: 2px solid $secondary-dark;
  @media (min-width: 992px) {
    &:hover {
      color: #fff !important;
      background: $secondary;
      border-color: $secondary;
    }
  }
}

#event-showcase .wall__nav .switch-title.active,
.donate__container .tabs__block .is-visible.is-active .switch-title,
.about__container #sec2 .tabs__block .is-visible.is-active .switch-title {
	color: #fff!important;
	background: $secondary-dark;
  @media (min-width: 992px) {
    &:hover {
      color: #fff !important;
          background: $secondary;
    }
  }
}

.switch__wrap{
  .splide__track {
    margin: 0 12%;
    .splide__list {
      height: auto;
      display: flex;
      .switch-title {
        margin: 0 1rem;
        font-size: 16px;
        font-weight: 400;
        font-family: 'Roboto', sans-serif!important;
        line-height: 23px;
        text-align: center;
        width: 100px;
      }
    }
  }
}
.splide{
  &.jc-center{
    .splide__list{
      justify-content: center;
    }
  }
  &__slide{
    opacity: 1 !important;
  }
  &__arrow{
    background: transparent !important;
    svg{
      fill: $green-dark !important;
    }
  }
}
</style>
