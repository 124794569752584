<template>
  <div class="fancycard" v-for="item in serviceResult" :key="item.ID">
    <div class="fancycard__inner">
      <div
        class="
          row
          justify-content-center
          align-items-center
          position-relative
          p-5
          pt-3 pt-sm-5
          px-4 px-sm-5
        "
        style="z-index: 2"
      >
        <div class="col-lg-4">
          <h3 class="fancycard__title d-none d-md-block" v-html="item.Title"></h3>
          <h3 class="fancycard__title d-block d-md-none">{{ $filters.removeHtmlTag(item.Title) }}</h3>
        </div>
        <div class="col-lg-7">
          <slider-simple
            :serviceResultSRFile="item.SRFile"
            class=""
          ></slider-simple>
        </div>
      </div>
    </div>
    <div class="fancycard__text" v-html="item.Result"></div>
  </div>
</template>

<script>
import SliderSimple from "@/components/SliderSimple.vue";

export default {
  components: {
    SliderSimple,
  },
  props: ["serviceResult"],
};
</script>

<style lang="scss">
.fancycard{
  .splide__track{
    border-radius: 12px;
    box-shadow: 1px 1px 5px 5px rgba(0, 0, 0, 0.05), 4px 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  .splide__slide{
    display: flex;
  }
  .splide__pagination{
    bottom: -1.8rem;
  }
}
</style>