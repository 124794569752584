<template>
  <div :id="props.id" class="pe-none"></div>
</template>

<script>
export default {
  name: "YoutubeIframe",
  props: ["id", "playerstate"],
  setup(props) {
    return { props }
  },
  mounted() {
    if (!window.YT) {
      const tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";
      // window.onYouTubeIframeAPIReady = this.loadVideo;

      const firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    } else {
      // this.loadVideo();
    }
    setTimeout(() => {
      this.loadVideo();
    }, 300);
  },
  methods: {
    loadVideo() {
      this.player = new window.YT.Player(`${this.props.id}`, {
        videoId: this.props.id,
        playerVars: {
          playlist: this.props.id,
          loop: 1,
          modestbranding: 1, 
          controls: 0,
          disablekb: 1,
          rel: 0,
          showinfo: 0,
          fs: 0,
          playsinline: 1,
          mute: 1,
          origin: window.location.origin
        },
        events: {
          onReady: this.onPlayerReady,
          onStateChange: this.onPlayerStateChange,
        },
      });
    },
    onPlayerReady(event) {
      event.target.playVideo();
    },
    onPlayerStateChange(event) {
      if (event.data == window.YT.PlayerState.ENDED) {
        console.log("影片播放完畢");
        this.$emit("playerstate", { state: "ENDED" });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.splide__slide iframe{
  width: 300%;
  height: 100%;
  margin-left: -100%;
}
</style>
