<template>
  <Splide :options="simpleOptions">
    <SplideSlide v-for="item in serviceResultSRFile" :key="item.ID">
      <div v-if="!item.Img.includes('youtube')" class="imgbox">
        <div class="imgbox__inner imgbox--ratio-5x3">
          <div
            class="imgbox__image"
            :style="{ backgroundImage: 'url(\'' + item.Img + '\')' }"
          ></div>
        </div>
      </div>
      <div v-else class="imgbox">
        <youtube-iframe :id="youtube_parser(item.Img)"></youtube-iframe>
      </div>
    </SplideSlide>
  </Splide>
</template>

<script>
import YoutubeIframe from "@/components/YoutubeIframe.vue";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    Splide,
    SplideSlide,
    YoutubeIframe
  },
  props: ["serviceResultSRFile"],
  setup() {
    const simpleOptions = {
      rewind: true,
      gap: "1rem",
      arrows: false,
      pagination: 'slider'
    };

    function youtube_parser(url){
      var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      var match = url.match(regExp);
      return (match && match[7].length==11)? match[7] : false;
    }

    return {
      simpleOptions,
      youtube_parser,
    };
  }
});
</script>

<style lang="scss">
.banner-ratio-2x1 {
  padding-top: 500px !important;
}
.splide__pagination{
  .splide__pagination__page {
    background: transparent;
    border: 3px solid #FFF;
    height: 12px;
    width: 12px;
    margin: 3px 0.3rem;
    opacity: 1;
    &.is-active{
      transform: scale(1);
      background: #FFF;
    }
  }
}
</style>
