<template>
  <div id="overlay" @click="close">
    <i class="icon icon-close"></i>
    <img :src="imgsource.Img" alt="" />
    <span>{{ imgsource.Title }}</span>
  </div>
</template>
<script>
export default {
  props: {
    imgsource: Object,
    close: Function,
  },
  mounted: function () {
    var overlay = document.getElementById("overlay");
    overlay.setAttribute(
      "style",
      "height:" +
        window.innerHeight +
        "px; " +
        "width:" +
        window.innerWidth +
        "px"
    );
    console.log(window.innerWidth);
  },
};
</script>

<style>
#overlay {
  position: fixed;
  width: 100vw;
  /* height: 100vh; */
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 9999;
}
#overlay .icon-close {
  /* margin-left: auto;
  margin-right: 4rem; */
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}
#overlay img {
  height: 80%;
  width: auto;
}

#overlay span {
  color: white;
  margin-top: 1rem;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

@media only screen and (max-width: 500px) {
  .image {
    width: 80%;
  }
  #overlay img {
    height: auto;
    width: 90%;
  }
}

@media only screen and (max-width: 800px) and (min-width: 500px) {
  .image {
    width: 30%;
  }
  #overlay img {
    height: auto;
    width: 90%;
  }
}
</style>
