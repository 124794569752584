<template>
  <div class="policy__container">
    <article class="custom-content">
      <div class="policy-banner d-flex justify-content-center bg-secondary">
        <h1 class="text-white">隱私權政策</h1>
      </div>
      <section id="sec1" class="sec pb-0">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-10">
              <h2 class="sec__subtitle text-start mb-3">政策總覽</h2>
              <p>本隱私及Cookies政策由財團法人許潮英慈善基金會（下稱「本會」）提供，向您說明本會網站可能蒐集哪些個人資料及該資料可能被如何使用。若您對本政策有任何問題或意見，請聯絡我們。本政策僅適用於透過本基金會網站所蒐集之個人資料的處理。</p>
            </div>
          </div>
        </div>
      </section>

      <section id="sec2" class="sec pb-0">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-10">
              <h2 class="sec__subtitle text-start mb-3">個人資料的蒐集</h2>
              <p>本會於其網站上蒐集不同類別之個人資料，其中可能包含私人和業務聯絡資訊（例如您的姓名、公司名稱、職稱、聯絡電話、地址及電子信箱）、您的身分識別資訊（例如您與本會的關係）及其他由您自行提供或本會為處理交易、服務、詢問或請求時所必要的資料。若未收到必要之個人資料，本會可能無法履行您的請求。本會另使用cookies來蒐集某些關於您對本會網站的使用資訊，例如您所使用電腦的IP位址、您使用的瀏覽器、作業系統類型以及其他類似資訊。</p>
            </div>
          </div>
        </div>
      </section>

      <section id="sec3" class="sec pb-0">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-10">
              <h2 class="sec__subtitle text-start mb-3">個人資料的使用</h2>
              <p>蒐集的個人資料可能用於回應您的詢問或請求、維持本會與您的關係、幫助本會維護和提昇網站及其服務的品質，以及蒐集個人資料時所列的任何其他用途。</p>
            </div>
          </div>
        </div>
      </section>

      <section id="sec4" class="sec pb-0">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-10">
              <h2 class="sec__subtitle text-start mb-3">個人資料的揭露</h2>
              <p>本會或關聯企業可能與上述目的有關，且符合need-to-know的授權人員分享本會網站蒐集的個人資料。</p>
              <p>本會亦可能將您的個人資料揭露予第三方供應商（尤其是為了使他們能夠執行某些網站的服務，例如網頁託管(web hosting)或維護服務）。</p>
              <p>在本會認為屬法令要求或符合法令規定之情形下，本會保留向任何其他第三方揭露此類個人資料的權利，包括：（1）為遵守法律程序或應政府要求；（2）為預防、調查、偵查或起訴對於本基金會網站或網絡技術完整性的刑事犯罪或攻擊；（3）為保護本基金會、網站使用者或公眾的權利、財產或安全。</p>
            </div>
          </div>
        </div>
      </section>

      <section id="sec5" class="sec pb-0">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-10">
              <h2 class="sec__subtitle text-start mb-3">個人資料的國際傳輸</h2>
              <p>本會對於個人資料的國際傳輸將透過合約、內部行為準則或其他保護機制來確保對您的個人資料提供足夠之保護。</p>
            </div>
          </div>
        </div>
      </section>

      <section id="sec6" class="sec pb-0">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-10">
              <h2 class="sec__subtitle text-start mb-3">Cookies的使用</h2>
              <p>本會網站使用cookies蒐集某些您對本網站的使用資訊，以改善您的使用體驗，以及分析和蒐集有關本會網路流量的統計數據。</p>
              <p>本會網站為上述目的而使用以下cookies：
                <ul>
                  <li>強制性cookies (mandatory cookies)以提供更佳的用戶安全性，或提昇本會網站使用體驗。</li>
                  <li>功能性cookies (functional cookies)以提供各功能來提昇用戶使用性，例如AddThis社交共享功能和Google搜索引擎。</li>
                  <li>性能cookies (performance cookies)以蒐集用戶如何使用本會網站，例如用戶所瀏覽之頁面。本類型cookies提供分析性資訊使本會能夠改善網站的使用性，Google Analytics為其中一個例子。</li>
                </ul>
              </p>
              <p>Cookies會在不同時間到期，大多數cookies在瀏覽器關閉時到期，第三方cookies則由第三方網站控制。</p>
              <p>您可以透過調整瀏覽器的設定（例如Microsoft Internet Explorer、Firefox、Chrome）隨時停用這些cookies。在此提醒您停用cookies可能會影響本會網站的使用性，例如若您停用了強制性cookies，您可能無法使用登入功能。</p>
            </div>
          </div>
        </div>
      </section>

      <section id="sec7" class="sec pb-0">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-10">
              <h2 class="sec__subtitle text-start mb-3">您的權利</h2>
              <p>視所適用法律之規定而定，您可能有權獲取關於您個人資料處理的資訊、取得您的個人資料、反對或限制對於您個人資料的處理、在適用之情形下行使您的資料可攜權，以及更正或刪除您的個人資料，並有權隨時撤回您對個人資料處理的同意。</p>
              <p>若您欲行使上述權利或了解更多關於您個人資料處理的資訊，請聯絡我們，本會將儘快回覆您的請求。</p>
              <p>若您認為您的個人資料未按照相關適用法律處理，您亦有權向監管機關提出申訴或根據適用法律尋求其他措施。</p>
            </div>
          </div>
        </div>
      </section>

      <section id="sec8" class="sec pb-0">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-10">
              <h2 class="sec__subtitle text-start mb-3">個人資料的保存</h2>
              <p>本會將在不超過法律許可之期間，以及任何情況下，不超過為達成個人資料之蒐集或處理目的所必須之期間內，保存您的個人資料。視所適用法律之規定而定，本會可能在下列情形時刪除個人資料：當個人資料已非原蒐集或處理目的所必須；當您撤回同意（而處理之法律依據是基於您的同意），並且沒有其他法律依據以供繼續處理時；當您反對資料的處理，且就該處理沒有其他沒有更重要的正當理由時；當您的個人資料被非法處理時；為遵守法律義務時。</p>
            </div>
          </div>
        </div>
      </section>

      <section id="sec9" class="sec pb-0">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-10">
              <h2 class="sec__subtitle text-start mb-3">本隱私及Cookies政策的變更</h2>
              <p>本會保留隨時修改本政策之權利，您可透過查看本頁頂部的"最近更新日期"以確認本政策是否已被修改。使用本會網站即代表您同意本政策各規定（包含本會對本政策之修改），因此您應定期查看本隱私權政策，以確保您了解其規定。</p>
            </div>
          </div>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      seoData: null,
      sTitle: null,
      sDescription: null,
      sKeywords: null,
      sSiteName: null,
      sImage: null,
      sType: null,
      currentURL: null,
    };
  },
  created() {
    var seoFD = new FormData();
    seoFD.append("mode", "raw_sct");
    seoFD.append("data", '{"class":"HciSEO","lang":"zh-TW"}');
    axios({
      method: "post",
      url: "https://nkg-web-api.japaneast.cloudapp.azure.com/Form/",
      data: seoFD,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      const currtUrlName = this.$route.name.toLowerCase();
      this.seoData = res.data.data.filter(item => (item.SetPage == currtUrlName));
      this.sTitle = this.seoData[0].title;
      this.sDescription= this.seoData[0].description;
      this.sKeywords = this.seoData[0].keywords;
      this.sSiteName = this.seoData[0].site_name;
      this.sImage = this.seoData[0].image;
      this.sType = this.seoData[0].type;
      this.currentURL = window.location.href;
      
      console.log("this.currentURL=====>", this.currentURL)

      const siteTitle = document.querySelector("title");
      siteTitle.innerText = this.sTitle;

      const seoDescriptionD = document.querySelector("meta[name='description']");
      seoDescriptionD.setAttribute('content', this.sDescription);

      const seoTitle = document.querySelector("meta[property='og:title']");
      seoTitle.setAttribute('content', this.sSiteName);

      const seoDescription = document.querySelector("meta[property='og:description']");
      seoDescription.setAttribute('content', this.sDescription);

      const seoSiteName = document.querySelector("meta[property='og:site_name']");
      seoSiteName.setAttribute('content', this.sSiteName);

      const seoImg = document.querySelector("meta[property='og:image']");
      seoImg.setAttribute('content', this.sImage);

      const seoType = document.querySelector("meta[property='og:type']");
      seoType.setAttribute('content', this.sType);

      const seoKeywords = document.querySelector("meta[name='keywords']");
      seoKeywords.setAttribute('content', this.sKeywords);

      const seoURL = document.querySelector("meta[property='og:url']");
      seoURL.setAttribute('content', this.currentURL);
    });
  }
}
</script>