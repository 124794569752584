<template>
  <div class="partner__container">
    <!-- <template :pagesBannerbb="pagesBannerbb">
      <banner-singlee v-if="pagesBannerbb.Pages === 'partner'"></banner-singlee>
    </template> -->
    <!-- BANNER -->
    <template v-for="item in pagesBanner" :key="item.ID">
      <banner-single v-if="item.Pages == 'partner'">
        <template #image>
          <div
            class="bannerbox__image d-none d-sm-block"
            :style="{ backgroundImage: 'url(' + item.Img + ')' }"
          ></div>
          <div
            class="bannerbox__image d-block d-sm-none"
            :style="{ backgroundImage: 'url(' + item.MImg + ')' }"
          ></div>
        </template>
        <template #title
          ><h1 class="bannerbox__title">{{ item.Title }}</h1></template
        >
        <template #desc
          ><p>{{ item.SubTitle }}</p></template
        >
        <template #mark>
          <img
            src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/mark-banner-primary.svg"
            class="img-fluid banner__mark"
          />
        </template>
      </banner-single>
    </template>

    <!-- MAIN -->
    <article class="bg-primary">
      <!-- SECTION_1 -->
      <section id="logo-list" class="sec">
        <img
          src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/mark-banner-third.svg"
          class="img-fluid sec-deco-bottom"
        />
        <div class="sec__content container">
          <h3 class="sec__subtitle">謝謝你的支持！</h3>

          <div class="row justify-content-center mb-3">
            <div class="col-lg-9">
              <p>
                感謝各方企業持續對許潮英慈善基金會的支持，認同與肯定我們的服務理念以及致力於關懷弱勢的願景，一同響應並擴大結合集團企業的人力與資源，守護弱勢族群，包括獨居長輩、新住民子女與環境保護議題等，更聚焦弱勢兒童，協助孩子發展音樂、美術、體育等多元才藝，點亮他們的生命與未來！
              </p>
            </div>
          </div>

          <div class="partner__block">
            <!-- 跑4:3 -->
            <div
              class="row justify-content-center d-none d-lg-flex"
              v-for="(item, i) in comResList"
              :key="i"
            >
              <div
                class="col-6 col-md-4 col-lg-3"
                v-for="items in item"
                :key="items.ID"
              >
                <div class="bubble-card">
                  <div class="bubble">
                    <img :src="items.Logo" class="img-fluid" /><br />
                  </div>
                  <h2 class="brand-title" v-html="items.Title"></h2>
                  <a :href="items.Link" target="_blank" v-if="items.Link"></a>
                </div>
              </div>
            </div>
            <!-- 跑正常 -->
            <div class="row justify-content-center d-flex d-lg-none">
              <div class="col-6" v-for="item in comResRwdList" :key="item.ID">
                <div class="bubble-card">
                  <div class="bubble">
                    <img :src="item.Logo" class="img-fluid" /><br />
                  </div>
                  <h2 class="brand-title" v-html="item.Title"></h2>
                  <a :href="item.Link" target="_blank" v-if="item.Link"></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- SECTION_2 -->
      <section id="event-showcase" class="sec pt-3">
        <h2 class="sec__title mb-4">社群暖心分享</h2>
        <div class="sec__content container-sm px-0 px-md-1">
          <div class="wall__block">
            <div class="col-md-10 offset-md-1">
              <nav class="wall__nav d-flex justify-content-center flex-wrap mb-4">
                <label
                  class="switch-title mb-2"
                  :class="{ active: galleryFilter === tag }"
                  :for="tag"
                  v-for="(tag,index) in eveImgTags"
                  :key="index"
                >
                  <input
                    type="radio"
                    :id="tag"
                    name="filters"
                    :value="tag"
                    @click="updateFilter(tag)"
                  />
                  <span class="me-1" v-if="tag!=='全部'">#</span> {{ tag }}
                </label>
              </nav>
            </div>
            <div class="wall__main">
              <!-- <infinite-scroll @infinite-scroll="loadDataFromServer"> -->
              <!-- Place the content of your page here. E.g a list of resources being fetched from a server -->
              <div class="row photo-wall">
                <div
                  class="col-4 p-1"
                  v-for="(item, index) in filteredImages"
                  :key="index"
                >
                <a :href="item.Link" target="_blank">
                  <img-box>
                    <template #image>
                      <div class="imgbox__inner imgbox--ratio-1x1 rounded-0">
                        <div
                          class="imgbox__image hover-style"
                          :style="{ backgroundImage: 'url(' + item.Img + ')' }"
                        ></div>
                      </div>
                      <!-- <i class="icon icon-fb-white"></i> -->
                    </template>
                  </img-box>
                  </a>
                </div>
              </div>
              <!-- </infinite-scroll> -->
            </div>
          </div>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
import axios from "axios";

import BannerSingle from "@/components/BannerSingle.vue";
import ImgBox from "@/components/ImgBox.vue";

export default {
  components: {
    BannerSingle,
    ImgBox,
  },
  data() {
    return {
      seoData: null,
      sTitle: null,
      sDescription: null,
      sKeywords: null,
      sSiteName: null,
      sImage: null,
      sType: null,
      currentURL: null,
      pagesBanner: [],
      comResList: [],
      comResRwdList: [],
      eveImgList: [],
      eveImgTags: [],
      eveImgListAll: [],
      galleryFilter: "全部",
      tagGroup: null,
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);

    var seoFD = new FormData();
    seoFD.append("mode", "raw_sct");
    seoFD.append("data", '{"class":"HciSEO","lang":"zh-TW"}');
    axios({
      method: "post",
      url: "https://nkg-web-api.japaneast.cloudapp.azure.com/Form/",
      data: seoFD,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      const currtUrlName = this.$route.name.toLowerCase();
      this.seoData = res.data.data.filter(item => (item.SetPage == currtUrlName));
      this.sTitle = this.seoData[0].title;
      this.sDescription= this.seoData[0].description;
      this.sKeywords = this.seoData[0].keywords;
      this.sSiteName = this.seoData[0].site_name;
      this.sImage = this.seoData[0].image;
      this.sType = this.seoData[0].type;
      this.currentURL = window.location.href;
      
      console.log("this.currentURL=====>", this.currentURL)

      const siteTitle = document.querySelector("title");
      siteTitle.innerText = this.sTitle;

      const seoDescriptionD = document.querySelector("meta[name='description']");
      seoDescriptionD.setAttribute('content', this.sDescription);

      const seoTitle = document.querySelector("meta[property='og:title']");
      seoTitle.setAttribute('content', this.sSiteName);

      const seoDescription = document.querySelector("meta[property='og:description']");
      seoDescription.setAttribute('content', this.sDescription);

      const seoSiteName = document.querySelector("meta[property='og:site_name']");
      seoSiteName.setAttribute('content', this.sSiteName);

      const seoImg = document.querySelector("meta[property='og:image']");
      seoImg.setAttribute('content', this.sImage);

      const seoType = document.querySelector("meta[property='og:type']");
      seoType.setAttribute('content', this.sType);

      const seoKeywords = document.querySelector("meta[name='keywords']");
      seoKeywords.setAttribute('content', this.sKeywords);

      const seoURL = document.querySelector("meta[property='og:url']");
      seoURL.setAttribute('content', this.currentURL);
    });

    var FD = new FormData();
    FD.append("mode", "raw_sct");
    FD.append("data", '{"class":"PagesBanner","lang":"zh-TW","sw":""}');
    axios({
      method: "post",
      url: "https://nkg-web-api.japaneast.cloudapp.azure.com/Form/",
      data: FD,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      this.pagesBanner = res.data.data;
      // res.data.data.forEach(function (item,index) {
      //   if (item.Pages === 'partner') {
      //     console.log("red", res.data.data);
      //     this.pagesBannerbb.push(res.data.data[index]);
      //   }else{
      //     console.log("red", 'not ok');
      //   }
      // });
    });

    var FD2 = new FormData();
    FD2.append("mode", "raw_sct");
    FD2.append("data", '{"class":"ComRes","lang":"zh-TW","sw":""}');
    axios({
      method: "post",
      url: "https://nkg-web-api.japaneast.cloudapp.azure.com/Form/",
      data: FD2,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      this.comResRwdList = res.data.data;

      var sizes = [4, 3],
        i = 0,
        j = 0,
        chunks = [];
      while (i < Object.keys(res.data.data).length) {
        chunks.push(res.data.data.slice(i, (i += sizes[j++ % sizes.length])));
      }
      this.comResList = chunks;
      console.log(chunks);
    });

    var FD3 = new FormData();
    FD3.append("mode", "raw_sct");
    FD3.append("data", '{"class":"EveImg","lang":"zh-TW","sw":""}');
    axios({
      method: "post",
      url: "https://nkg-web-api.japaneast.cloudapp.azure.com/Form/",
      data: FD3,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      this.eveImgList = res.data.data;
      this.eveImgTags = res.data.HciTag;
    });
  },
  computed: {
    filteredImages: function () {
      if (this.galleryFilter === "全部") {
        return this.eveImgList;
      } else {
        return this.eveImgList.filter((image) => {
          let imagesTags = image.HciTag.split(",");
          return imagesTags.includes(this.galleryFilter);
        });
      }
    },
  },
  methods: {
    updateFilter(filterName) {
      this.galleryFilter = filterName;
      console.log("this.galleryFilter====", filterName);
    },
  },
  handleScroll() {
    //網路的做法
    // this.active = window.scrollY + window.screen.height >= document.body.scrollHeight
    this.active =
      window.innerHeight + window.scrollY >= document.body.offsetHeight;
    if (this.active) {
      console.log("call next api");
    }
  },
};
</script>

<style lang="scss" scoped>
#logo-list {
  padding-bottom: 4rem;
  @media (max-width:576px) {
    padding-bottom: 1rem;
  }
}

.switch-title {
  //height: 2.8rem;
  margin: 0 0.4rem;
  input {
    display: none;
  }
}
.imgbox .icon-fb-white {
  position: absolute;
  right: .2rem;
  bottom: .8rem;
}

.hover-style:hover:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.25);
  cursor: pointer;
}

.bubble-card {
  position: relative;
  a {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 100%;
  }
}
</style>
