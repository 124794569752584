import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import About from "../views/About.vue";
import Service from "../views/Service.vue";
import Volunteer from "../views/Volunteer.vue";
import Donate from "../views/Donate.vue";
import Contact from "../views/Contact.vue";
import Partner from "../views/Partner.vue";
import Policy from "../views/Policy.vue";
import NotFoundComponent from "../views/NotFoundComponent.vue";

const routes = [
    {
        path: "/",
        redirect:'/zh-TW/home'
    },
    {
        path: "/:lang",
        redirect:'/zh-TW/home'
    },
    {
        path: "/:lang/404",
        name: "404",
        component: NotFoundComponent,
    },
    {
        path: "/:lang/home",
        name: "Home",
        component: Home,
    },
    {
        path: "/:lang/about",
        name: "About",
        component: About,
    },
    {
        path: "/:lang/news",
        name: "News",
        component: () =>
        import ("../views/News/index.vue"),
    },
    {
        path: "/:lang/news/:id",
        name: "NewsDetail",
        component: () =>
        import ("../views/News/_id.vue"),
    },
    {
        path: "/:lang/service",
        name: "Service",
        component: Service,
    },
    {
        path: "/:lang/story",
        name: "Story",
        component: () =>
        import ("../views/Story/index.vue"),
    },
    {
        path: "/:lang/story/:id",
        name: "StoryDetail",
        component: () =>
        import ("../views/Story/_id.vue"),
    },
    {
        path: "/:lang/volunteer",
        name: "Volunteer",
        component: Volunteer,
    },
    {
        path: "/:lang/donate",
        name: "Donate",
        component: Donate,
    },
    {
        path: "/:lang/contact",
        name: "Contact",
        component: Contact,
    },
    {
        path: "/:lang/partner",
        name: "Partner",
        component: Partner,
    },
    {
        path: "/:lang/policy",
        name: "policy",
        component: Policy,
    },
    {
        path: "/:lang/*",
        redirect:'/zh-TW/404'
    },
    
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to,form) {
        if (to.hash) {
            if(to.name !== form.name){
                document.body.scrollIntoView();
                setTimeout(() => {
                    const el = window.location.href.split("#")[1];
                    if (el.length) {
                        document.getElementById(el).scrollIntoView({ behavior: "smooth", block: 'center' });
                    }
                }, 500)
            }
            return { el: to.hash }
        } else {
            //safari
            window.pageYOffset = 0
            return { left: 0, top: 0 }
        }

        // if (to.hash === '#sec2') {
        //   const position = {}
        //   position.el = to.hash
        //   // position.behavior='smooth'
        //   // position.offset = { y: 200 }

        //   return new Promise((resolve) => {
        //     setTimeout(() => {
        //       resolve(position)
        //     }, 500)
        //   })

        // } else {
        //   return {
        //     el: { x: 0, y: 0 },
        //     behavior: 'smooth'
        //   }
        // }
    },
});

//路由跳轉後的頁面一率回頂端
// router.beforeEach(() => {
//   //chrome
//   document.body.scrollTop = 0
//   //firefox
//   document.documentElement.scrollTop = 0
//   //safari
//   window.pageYOffset = 0
// })

router.beforeEach((to) => {
    if(to.fullPath!=='/zh-TW/'){
        if(to.params.lang!=='zh-TW'){
            router.push('/zh-TW/404');
        }
    }else{
        router.push('/zh-TW/home');
    }
});

export default router;